import { Typography, Box } from '@mui/material';
import Link from '../../../components/ui/Link';

const CheckMailbox = () => (
  <Box width="100%" padding="0 70px">
    <Box marginBottom="30px">
      <Typography variant="h3" textTransform="uppercase">
        Check your mailbox
      </Typography>
    </Box>
    <Box>
      <Typography variant="h4">
        Your verification code has been emailed.
      </Typography>
      <Box marginTop="5px">
        <Typography variant="h5">
          Remember your password? &nbsp;
          <Link content="Log In" to="/login" />
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default CheckMailbox;
