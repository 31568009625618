import React, { useEffect, useRef, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import {
  Box,
  List,
  CssBaseline,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  IconButton,
  Toolbar,
  Drawer,
  Divider,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { toast } from 'react-toastify';

import { getUser, logOut } from '../../store/authentication/actions';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { ReactComponent as Logo } from '../../static/icons/k’ept health.svg';
import { MenuItems, Roles, tosEULADate } from '../../utils/constants';
import { showCaseForm } from '../../store/cases/actions';
import { getUsers } from '../../store/practitioners/actions';

const drawerWidth = 240;
const mobileWidth = 900;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ width, marginTop }) => ({
    width: width < 900 ? '100%' : `calc(100% - ${drawerWidth}px)`,
    flexGrow: 1,
    minHeight: '100vh',
    marginTop,
    float: 'right',
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  background: 'white',
  boxShadow: 'none',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ width, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: width > 900 ? '0 auto' : '0',
  padding: width > 900 ? '10px 0 30px' : '10px 0 10px',
  float: width > 900 ? 'none' : 'right',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useStyles = makeStyles(() => ({
  logoBox: {
    margin: '50px auto 15px',
  },
  listItemButton: {
    minHeight: 30,
    height: '40px',
    justifyContent: 'initial',
    px: 2.5,
  },
  listItemIcon: {
    margin: '0 15px !important',
    minWidth: '0 !important',
    justifyContent: 'center',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
      backgroundColor: 'rgba(240, 240, 240, 1) !important',
    },
  },
}));

const AuthenticatedLayout = () => {
  const [active, setActive] = useState('Cases');
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(width > mobileWidth);
  const headerRef = useRef(null);
  const user = useSelector((state) => state.authentication.user);
  const [headerHeight, setHeaderHeight] = useState(50);
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );
  const practitioners = useSelector((state) => state.practitioners.data);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user && user.role === Roles.ADMIN && !practitioners) {
      dispatch(getUsers());
    }
  }, [practitioners]);

  useEffect(() => {
    if (headerRef?.current?.offsetHeight) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, [headerRef]);

  useEffect(() => {
    if (user === null) {
      dispatch(getUser());
    } else {
      const menuItem = MenuItems.find(
        (item) => item.endpoint === location.pathname
      );
      if (menuItem) {
        setActive(menuItem.title);
      }

      if (
        location.pathname !== '/terms' &&
        user?.role === Roles.PRACTITIONER &&
        new Date(user?.tosEULAat) < tosEULADate
      ) {
        navigate('/terms');
        toast.error(
          'You must accept the TOS before continuing with K’ept Health.',
          { toastId: 'tos-accept' }
        );
      }
    }
  }, [user, location]);

  return (
    user && (
      <Box>
        <CssBaseline />
        {width < mobileWidth && (
          <AppBar position="fixed" open={open} ref={headerRef}>
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={() => setOpen((oldOpen) => !oldOpen)}
                edge="start"
                sx={{
                  mr: 2,
                  ...(open && { display: 'none' }),
                  color: '#6B7FA6',
                }}
              >
                <MenuIcon />
              </IconButton>
              <Box margin="auto">
                <Logo />
              </Box>
            </Toolbar>
          </AppBar>
        )}
        <Drawer
          className={classes.drawer}
          variant={width < mobileWidth ? 'persistent' : 'permanent'}
          open={width < mobileWidth ? open : true}
        >
          <DrawerHeader>
            {width > mobileWidth ? (
              <Box className={classes.logoBox}>
                <Logo />
              </Box>
            ) : (
              <IconButton onClick={() => setOpen((oldOpen) => !oldOpen)}>
                <ChevronLeftIcon sx={{ color: 'white', fontSize: '35px' }} />
              </IconButton>
            )}
          </DrawerHeader>
          <List sx={{ padding: '15px 0', height: '100%', border: '0' }}>
            {MenuItems.filter(
              (i) => i?.role === undefined || i.role === user.role
            ).map((item) => {
              if (item.title === 'Sign Out') {
                return (
                  <Box
                    key={item.title}
                    position="absolute"
                    bottom="0"
                    width="100%"
                  >
                    <Divider
                      sx={{
                        background: '#D4D4D4',
                        borderBottomWidth: 'inherit',
                        height: '0.8px',
                      }}
                      light
                    />
                    <ListItem
                      disablePadding
                      sx={{
                        display: 'block',
                        padding: '15px 10px',
                      }}
                    >
                      <ListItemText sx={{ textAlign: 'center' }}>
                        <Grid container>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <AccountCircleIcon
                              sx={{ height: '100%', verticalAlign: 'middle' }}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography variant="h5" fontWeight="bold">
                              {user?.role === Roles.PRACTITIONER && 'Dr. '}
                              {user !== null
                                ? `${user.firstName} ${user.lastName}`
                                : ''}
                            </Typography>
                            <Typography>
                              {user &&
                                user.role &&
                                user.role.charAt(0).toUpperCase() +
                                  user.role.slice(1).toLowerCase()}
                            </Typography>
                          </Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <ListItemButton
                              onClick={() => {
                                dispatch(logOut())
                                  .unwrap()
                                  .then(() => navigate('/login'));
                              }}
                              disabled={item?.disabled}
                              className={classes.listItemButton}
                              sx={{
                                ':hover': {
                                  background: 'none',
                                },
                                height: '100%',
                                verticalAlign: 'middle',
                              }}
                            >
                              <LogoutIcon />
                            </ListItemButton>
                          </Grid>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  </Box>
                );
              }
              return (
                <Box key={item.title}>
                  <ListItem
                    disablePadding
                    sx={{
                      display: 'block',
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      ...(item.title === active && {
                        backgroundColor: 'rgba(255, 247, 217, 1)',
                      }),
                    }}
                  >
                    <ListItemButton
                      onClick={() => {
                        setActive(item.title);
                        if (item.title === 'Sign Out') {
                          dispatch(logOut())
                            .unwrap()
                            .then(() => navigate('/login'));
                        } else {
                          dispatch(showCaseForm(false));
                          navigate(item.endpoint);
                        }
                        if (width < mobileWidth) {
                          setOpen(false);
                        }
                      }}
                      disabled={item?.disabled}
                      className={classes.listItemButton}
                      sx={{
                        ...(item.title === active && {
                          backgroundColor: '#FFFFFF1A',
                        }),
                      }}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        <item.icon
                          style={{
                            ...(item.title === active && {
                              fill: 'rgba(186, 150, 15, 1)',
                            }),
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          sx={{
                            ...(item.title === active
                              ? { color: 'black' }
                              : { color: 'rgba(82, 82, 82, 1)' }),
                          }}
                          variant="h5"
                          textTransform="uppercase"
                          fontWeight="bold"
                        >
                          {item.title}{' '}
                          {item.title === 'Practitioners' && practitioners &&
                            `(${practitioners.length})`}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Box>
              );
            })}
          </List>
        </Drawer>
        <Main open={open} width={width}>
          <Box
            marginLeft="auto"
            marginRight={width < mobileWidth && open ? 'none' : 'auto'}
            sx={{
              width: '100%',
              marginTop: width < 700 ? `${headerHeight}px` : '10px',
              height: `calc(100% - ${headerHeight}px)`,
            }}
          >
            <Outlet />
          </Box>
        </Main>
      </Box>
    )
  );
};

export default AuthenticatedLayout;
