import { useEffect } from 'react';

import { Grid, Typography, Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';

import Form from '../../components/Form';
import { confirmResetPassword } from '../../store/authentication/actions';
import {
  confirmResetPasswordSchema,
  formSteps,
} from './components/formDetails';
import PrimaryButton from '../../components/ui/PrimaryButton';
import Link from '../../components/ui/Link';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: '100%',
    padding: '0 70px',
  },
  title: {
    marginBottom: '30px',
    textTransform: 'uppercase',
  },
  gridItem: {
    textAlign: 'right',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '15px !important',
      textAlign: 'center',
    },
  },
}));

const ResetPasswordConfirm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.authentication.loading);
  const classes = useStyles();

  const form = useForm({
    resolver: yupResolver(confirmResetPasswordSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!searchParams.has('code')) {
      toast.error(
        "It looks like the link you've clicked is not valid. Please try again."
      );
      navigate('/reset-password');
    }
  }, [searchParams]);

  const handleSubmit = (values) => {
    dispatch(
      confirmResetPassword({ ...values, code: searchParams.get('code') })
    )
      .unwrap()
      .then((responseData) => {
        toast.success(responseData?.message);
        navigate('/login');
      });
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.title}>
        <Typography variant="h3">Enter a new password</Typography>
      </Box>
      <Form
        steps={formSteps}
        form={form}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className={classes.gridItem}
            >
              <Typography variant="h5">
                Have an account? &nbsp;
                <Link content="Log In" to="/login" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.gridItem}
            >
              <PrimaryButton
                type="submit"
                sx={{
                  backgroundColor: '#FFD734',
                  borderRadius: '0px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                disabled={isLoading}
              >
                Confirm
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default ResetPasswordConfirm;
