import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';

import ErrorBox from './ErrorBox';

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ error }) => ({
  '&.MuiInputBase-root': {
    borderColor: error && '#C75C57',
    '&:hover fieldset': {
      border: error ? '1px solid #C75C57' : '1px solid black',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: error ? '1px solid #C75C57' : '1px solid black',
    },
  },
  '&.MuiPaper-root': {
    minWidth: '150px !important',
  },
}));

const SelectField = ({ field, register, control, errors, isLoading }) => {
  const renderLabels = (fieldVal) =>
    field.options.map((option, index) => {
      let item;
      if (field.multiple) {
        item = (
          <>
            <Checkbox
              sx={{
                '&.MuiCheckbox-root': {
                  color: 'black',
                },
              }}
              checked={
                fieldVal?.value === undefined
                  ? false
                  : fieldVal.value.includes(option.value)
              }
            />
            <ListItemText primary={option?.label} />
          </>
        );
      } else if (field.isImages) {
        if (option.value === '') {
          item = <ListItemText primary={option?.label} />;
        } else if (option && option.visibleLabel !== undefined) {
          item = (
            <div>
              <span>{option.visibleLabel}</span>
              <img src={option.label} alt="test" style={{ width: '100px' }} />
            </div>
          );
        } else {
          item = (
            <img src={option.label} alt="test" style={{ width: '100px' }} />
          );
        }
      } else {
        item = <ListItemText primary={option?.label} />;
      }

      return (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={index === 0}
          selected={index === 0}
        >
          {item}
        </MenuItem>
      );
    });

  return (
    <FormControl component="fieldset" sx={{ minWidth: '100%', width: '150px' }}>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (
        <>
          <FormLabel component="legend" sx={{ mb: 2 }}>
            <Typography
              variant="h5"
              sx={{
                color: '#525252',
              }}
              fontSize="16px"
              fontWeight="bold"
            >
              {field?.label}
              {field.isRequired && <span>*</span>}
            </Typography>
            <Typography variant="body1" component="span">
              {field?.helpText}
            </Typography>
          </FormLabel>
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              minHeight: '56px',
              ...(field?.isImages && { width: '160px', margin: 'auto' }),
            }}
          >
            <Controller
              control={control}
              className={field.fieldClass}
              name={field.name}
              inputRef={register(field.name)}
              render={({ field: fieldVal }) => (
                <StyledSelect
                  error={!!errors[field.fieldId]}
                  style={{ width: '100%' }}
                  disabled={field.disabled}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root.Mui-selected': {
                          backgroundColor: 'rgba(240, 240, 240, 1)',
                        },
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor: 'rgba(240, 240, 240, 0.5)',
                        },
                        '& .MuiMenuItem-root.Mui-selected:hover': {
                          backgroundColor: 'rgba(240, 240, 240, 0.5)',
                        },
                      },
                    },
                  }}
                  multiple={field?.multiple}
                  {...{
                    ...fieldVal,
                    value: field.multiple
                      ? fieldVal.value === undefined
                        ? []
                        : fieldVal.value
                      : fieldVal.value,
                  }}
                  renderValue={(val) => {
                    if (field.multiple && val !== undefined) {
                      return field.options
                        .filter((item) => val.includes(item.value))
                        .map((item) => item.label)
                        .join(', ');
                    }
                    if (field.isImages && val !== undefined) {
                      return (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          {_.get(errors, field.name)?.message && (
                            <WarningIcon sx={{ color: '#C75C57', mr: 1 }} />
                          )}
                          <img
                            src={
                              field.options.find((item) => item?.value === val)
                                ?.label
                            }
                            alt="test"
                            style={{ width: '100px' }}
                          />
                        </Box>
                      );
                    }
                    return (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {_.get(errors, field.name)?.message && (
                          <WarningIcon sx={{ color: '#C75C57', mr: 1 }} />
                        )}
                        {
                          field.options.find(
                            (item) =>
                              item?.value === (val === undefined ? '' : val)
                          )?.label
                        }
                      </Box>
                    );
                  }}
                  displayEmpty
                  autoWidth={field?.isImages}
                >
                  {renderLabels(fieldVal)}
                </StyledSelect>
              )}
            />
          </Box>
          <ErrorBox
            message={_.get(errors, field.name)?.message}
            showIcon={false}
          />
        </>
      )}
    </FormControl>
  );
};

SelectField.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default SelectField;
