import { styled } from '@mui/system';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const StyledMuiButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }) => ({
  backgroundColor: disabled ? 'rgba(120, 120, 120, 1)' : 'white',
  border: disabled ? '2px solid rgba(161, 161, 161, 1)' : '2px solid #525252',
  borderRadius: '0px',
  color: disabled ? 'rgba(161, 161, 161, 1)' : 'rgba(36, 36, 36, 1)',
  padding: '7px 45px',
  fontFamily: 'Avenir',
  fontWeight: 'bold',
}));

const SecondaryButton = ({
  text,
  onClick,
  disabled,
  buttonProps,
  progressProps,
}) => (
  <StyledMuiButton onClick={onClick} disabled={disabled} {...buttonProps}>
    {disabled ? (
      <CircularProgress size={20} sx={{ color: 'white' }} {...progressProps} />
    ) : (
      text
    )}
  </StyledMuiButton>
);

SecondaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  buttonProps: PropTypes.objectOf(PropTypes.object),
  progressProps: PropTypes.objectOf(PropTypes.object),
};

SecondaryButton.defaultProps = {
  buttonProps: {},
  progressProps: {},
  onClick: () => {},
};

export default SecondaryButton;
