import { styled } from '@mui/system';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const StyledMuiButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }) => ({
  backgroundColor: disabled
    ? 'rgba(120, 120, 120, 1) !important'
    : 'rgba(25, 25, 25, 1)',
  color: 'white',
  ...(disabled ? { pointerEvents: 'none' } : {}),
  padding: '10px 50px',
  fontFamily: 'Avenir',
  '&:hover': {
    backgroundColor: 'rgba(36, 36, 36, 1)',
  },
}));

const PrimaryButton = ({
  children,
  onClick,
  disabled,
  isLoading,
  ...props
}) => (
  <StyledMuiButton
    size="large"
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {isLoading ? (
      <CircularProgress sx={{ color: 'white' }} size={30} />
    ) : (
      children
    )}
  </StyledMuiButton>
);

PrimaryButton.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

PrimaryButton.defaultProps = {
  onClick: () => {},
  disabled: false,
  isLoading: false,
};

export default PrimaryButton;
