import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from '../../utils/api';
import { createFormData } from '../../utils/utils';

export const getCases = createAsyncThunk(
  'cases/get',
  async (values, { rejectWithValue }) =>
    makeRequest('/cases', 'GET', values, rejectWithValue)
);

export const getCaseById = createAsyncThunk(
  'cases/getById',
  async (values, { rejectWithValue }) =>
    makeRequest(`/cases/${values}`, 'GET', values, rejectWithValue)
);

export const createCase = createAsyncThunk(
  'cases/create',
  async (values, { rejectWithValue }) =>
    makeRequest(`/cases`, 'POST', createFormData(values), rejectWithValue)
);

export const updateCase = createAsyncThunk(
  'cases/update',
  async (values, { rejectWithValue }) =>
    makeRequest(
      `/cases/${values.id}`,
      'PUT',
      createFormData(values),
      rejectWithValue
    )
);

export const showCaseForm = createAction('cases/showCaseForm');
