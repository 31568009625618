import Login from '../views/Login';
import Register from '../views/Register';
import VerificationCode from '../views/EmailVerification';
import ResetPassword from '../views/ResetPassword';
import ResetPasswordConfirm from '../views/ResetPasswordConfirm';
import Landing from '../views/Landing';
import CaseForm from '../views/Cases/components/CaseForm';
import Cases from '../views/Cases/Cases';
import Gallery from '../views/Gallery';
import Terms from '../views/Terms';
import Welcome from '../views/Welcome';
import PractitionerTable from '../views/PractitionerTable';

export const PublicRoutes = [
  {
    path: '',
    view: Landing,
    sidePanel: false,
  },
  {
    path: '/login',
    view: Login,
  },
  {
    path: '/register',
    view: Register,
  },
  {
    path: '/verification',
    view: VerificationCode,
  },
  {
    path: '/reset-password',
    view: ResetPassword,
  },
  {
    path: '/reset-password/confirm',
    view: ResetPasswordConfirm,
  },
];

export const PrivateRoutes = [
  {
    path: '/cases',
    view: Cases,
  },
  {
    path: '/cases/:id',
    view: CaseForm,
  },
  {
    path: '/gallery',
    view: Gallery,
  },
  {
    path: '/terms',
    view: Terms,
  },
  {
    path: '/welcome',
    view: Welcome,
  },
  {
    path: '/practitioners',
    view: PractitionerTable,
  },
];
