import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import Form from '../../../components/Form';
import { resetPassword } from '../../../store/authentication/actions';
import { resetPasswordSchema, formSteps } from './formDetails';
import PrimaryButton from '../../../components/ui/PrimaryButton';
import Link from '../../../components/ui/Link';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: '100%',
    padding: '0 70px',
  },
  title: {
    marginBottom: '30px',
    textTransform: 'uppercase',
  },
  gridItem: {
    textAlign: 'right',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '15px !important',
      textAlign: 'center',
    },
  },
  leftAlign: {
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
}));

const ResetPassword = ({ setActiveStep }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.authentication.loading);
  const classes = useStyles();

  const form = useForm({
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange',
  });

  const handleSubmit = (values) => {
    dispatch(resetPassword(values))
      .unwrap()
      .then(() => setActiveStep(1));
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.title}>
        <Typography variant="h3">Reset your password</Typography>
      </Box>
      <Form
        steps={formSteps}
        form={form}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className={classes.gridItem}
            >
              <Typography variant="h5" className={classes.leftAlign}>
                Remembered your password? &nbsp;
                <Link content="Log In" to="/login" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.gridItem}
            >
              <PrimaryButton
                type="submit"
                sx={{
                  backgroundColor: '#FFD734',
                  borderRadius: '0px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                disabled={isLoading}
              >
                Reset Password
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

ResetPassword.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
};

export default ResetPassword;
