import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import authenticationReducer from './authentication/reducer';
import casesReducer from './cases/reducer';
import filesReducer from './files/reducer';
import practitionersReducer from './practitioners/reducer';

const logger = createLogger();

const combinedReducer = combineReducers({
  authentication: authenticationReducer,
  cases: casesReducer,
  files: filesReducer,
  practitioners: practitionersReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'authentication/logout/fulfilled') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'production'
      ? getDefaultMiddleware()
      : [...getDefaultMiddleware(), logger],
});

export default store;
