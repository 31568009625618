import axios from 'axios';
import { toast } from 'react-toastify';

export const clientApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const refreshApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

clientApi.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshRequest = await refreshApi.get('/auth/refresh');

        if (refreshRequest.status === 200) {
          window.localStorage.setItem(
            'keptHealth',
            JSON.stringify({
              accessTokenExpire: refreshRequest.data.accessTokenExpire,
              refreshTokenExpire: refreshRequest.data.refreshTokenExpire,
            })
          );
          return clientApi(originalRequest);
        }
        window.location.href = '/login';
      } catch (err) {
        window.location.href = '/login';
      }
    } else {
      throw error;
    }
  }
);

// eslint-disable-next-line consistent-return
export const makeRequest = async (
  url,
  method,
  payload,
  rejectWithValue,
  showErrors = true
) => {
  try {
    const data = await clientApi(url, {
      method,
      data: payload,
    });
    return data?.data;
  } catch (error) {
    const errorData = error?.response?.data?.message || null;
    if (error?.code === 'ERR_NETWORK') {
      toast.error('Internal Server Error');
    } else {
      if (showErrors && errorData !== null) {
        toast.error(error?.response?.message || errorData);
      }
      if (rejectWithValue !== null) {
        return rejectWithValue(errorData);
      }
    }
    return errorData;
  }
};
