import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from '../../utils/api';

export const registerUser = createAsyncThunk(
  'authentication/register',
  async (values, { rejectWithValue }) =>
    makeRequest('/auth/register', 'POST', values, rejectWithValue)
);

export const login = createAsyncThunk(
  'authentication/login',
  (values, { rejectWithValue }) =>
    makeRequest('/auth/login', 'POST', values, rejectWithValue)
);

export const logOut = createAsyncThunk(
  'authentication/logout',
  (values, { rejectWithValue }) =>
    makeRequest('/auth/logout', 'POST', values, rejectWithValue)
);

export const verifyAccount = createAsyncThunk(
  'authentication/emailVerification',
  (values, { rejectWithValue }) =>
    makeRequest('/auth/email/verification', 'POST', values, rejectWithValue)
);

export const resendVerificationCode = createAsyncThunk(
  'authentication/emailResendVerification',
  async ({ email }, { rejectWithValue }) =>
    makeRequest('/auth/email/resend', 'POST', { email }, rejectWithValue)
);

export const resetPassword = createAsyncThunk(
  'authentication/resetPassword',
  async (values, { rejectWithValue }) =>
    makeRequest('/auth/password/reset', 'POST', values, rejectWithValue)
);

export const confirmResetPassword = createAsyncThunk(
  'authentication/confirmResetPassword',
  async (values, { rejectWithValue }) =>
    makeRequest('/auth/password/confirm', 'POST', values, rejectWithValue)
);

export const getUser = createAsyncThunk(
  'authentication/getUser',
  async (values, { rejectWithValue }) =>
    makeRequest('/auth/user', 'GET', values, rejectWithValue)
);

export const patchUser = createAsyncThunk(
  'authentication/patchUser',
  async (values, { rejectWithValue }) =>
    makeRequest('/auth/user', 'PATCH', values, rejectWithValue)
);
