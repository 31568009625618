import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import WarningIcon from '@mui/icons-material/Warning';

const ErrorBox = ({ message, showIcon }) =>
  message && (
    <Box mt={1.5}>
      {showIcon && <WarningIcon sx={{ color: '#C75C57', mr: 1 }} />}
      <Typography
        variant="h5"
        fontSize="17px"
        component="span"
        sx={{ color: '#C75C57', verticalAlign: 'super' }}
      >
        {message}
      </Typography>
    </Box>
  );

ErrorBox.propTypes = {
  message: PropTypes.string,
  showIcon: PropTypes.bool,
};

ErrorBox.defaultProps = {
  message: '',
  showIcon: true,
};

export default ErrorBox;
