import * as yup from 'yup';
import {
  AGE_RANGES,
  ALLOWED_FILE_EXTENSIONS,
  DURATIONS,
  LOCATIONS,
  PAIN_QUALITY,
  SEVERITY,
  SKIN_TONES,
  SYMPTOMS,
  TIMINGS,
} from '../../../utils/constants';

export const step1Schema = yup.object().shape({});

const getImageDimensions = (file) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve([img.width, img.height]);
    img.onerror = reject;
    img.src = window.URL.createObjectURL(file);
  });

export const step2Schema = yup
  .object()
  .shape({
    files: yup
      .array()
      .of(
        yup.object().shape({
          files: yup
            .array()
            .required('Please upload your files.')
            .min(1, 'You must upload at least one file.')
            .test(
              'check-file-type',
              `The allowed file types are: ${ALLOWED_FILE_EXTENSIONS.join(
                ', '
              )}.`,
              (files) =>
                !files ||
                files
                  .map((file) =>
                    ALLOWED_FILE_EXTENSIONS.includes(
                      file.name.slice(
                        ((file.name.lastIndexOf('.') - 1) >>> 0) + 2
                      )
                    )
                  )
                  .every((i) => i)
            )
            .test(
              'check-file-size',
              'The minimum accepted size for images is 1024x768 pixels.',
              async (val) => {
                let result = true;
                if (val) {
                  const files = val.filter(
                    (file) =>
                      file instanceof File &&
                      ['jpeg', 'jpg', 'png'].includes(
                        file.name.slice(
                          ((file.name.lastIndexOf('.') - 1) >>> 0) + 2
                        )
                      )
                  );
                  result = await Promise.all(
                    files.map(async (file) => {
                      const [width, height] = await getImageDimensions(file);
                      return width >= 700 || height >= 768;
                    })
                  );
                  result = result.every((i) => i);
                }
                return result;
              }
            ),
          condition: yup
            .string('The condition field is not valid.')
            .required('The condition field is required.'),
          skinTone: yup
            .string('The skin tone type is not valid.')
            .required('The skin tone type is required.')
            .test('check-tone', 'The skin tone field is not valid.', (val) =>
              SKIN_TONES.map((i) => i.value).includes(val)
            ),
        })
      )
      .strict()
      .required('Please upload your files.')
      .min(1, 'You must upload at least one file.'),
    acknowledgeContribution: yup.string().required('Please select an option.'),
  })
  .required();

export const step3Schema = yup.object().shape({
  locations: yup
    .array()
    .min(1, 'You must select at least one location.')
    .required('The location field is required.')
    .test('check-location', 'The location field is not valid.', (val) => {
      if (val === undefined) {
        return true;
      }
      const locationValues = LOCATIONS.map((i) => i.value);
      return val
        .map((item) => locationValues.includes(item))
        .every((item) => item);
    }),
  duration: yup
    .string('The duration field is not valid.')
    .required('The duration field is required.')
    .test('check-duration', 'The duration field is not valid.', (val) =>
      DURATIONS.map((item) => item.value).includes(val)
    ),
  timing: yup
    .string('The timing field is not valid.')
    .required('The timing field is required.')
    .test('check-timing', 'The timing field is not valid.', (val) =>
      TIMINGS.map((item) => item.value).includes(val)
    ),
  ageRange: yup
    .string('The age range field is not valid.')
    .required('The age range is required.')
    .test('check-age', 'The age range is not valid', (val) =>
      AGE_RANGES.map((item) => item.value).includes(val)
    ),
  modifyingFactors: yup
    .string('The modifying factors field is not valid.')
    .required('The modifying factors field is required.'),
  healthFactors: yup
    .string('The health factors field is not valid.')
    .required('The health factors field is required.'),
});

export const step4Schema = (userDetails) =>
  yup.object().shape({
    patient: yup.object().shape({
      symptoms: yup
        .array()
        .min(1, 'You must select at least one symptom.')
        .required('The symptoms is not required.')
        .test('check-symptoms', 'The symptoms field is not valid.', (val) => {
          if (val === undefined) {
            return true;
          }
          const symptomsValues = SYMPTOMS.map((i) => i.value);
          return val
            .map((item) => symptomsValues.includes(item))
            .every((item) => item);
        }),
      painQuality: yup
        .string('The pain quality field is not valid.')
        .required('The pain quality field is required.')
        .test(
          'check-pain-quality',
          'The pain quality field is not valid.',
          (val) => PAIN_QUALITY.map((item) => item.value).includes(val)
        ),
      severity: yup
        .string('The severity field is not valid.')
        .required('The severity field is required.')
        .test('check-severity', 'The severity field is not valid.', (val) =>
          SEVERITY.map((item) => item.value).includes(val)
        ),
    }),
    caseBrief: yup.string('The case brief field is not valid'),
    signature: yup
      .string('The signature is not valid.')
      .required('The signature is required.')
      .trim()
      .test(
        'check-signature',
        userDetails
          ? `The signature must match ${userDetails.firstName} ${userDetails.lastName}.`
          : 'The signature is not valid.',
        (val) =>
          userDetails &&
          val.toLowerCase() ===
            `${userDetails.firstName.toLowerCase()} ${userDetails.lastName.toLowerCase()}`
      ),
  });

export const formSteps = (userDetails) => [
  {
    stepNo: 1,
    schema: step2Schema,
    name: 'files',
    multiple: {
      name: 'files',
      placeholder: 'Case File',
      defaultValues: {
        files: [],
        condition: '',
      },
    },
    fields: [
      [
        {
          name: 'files',
          fieldId: 'files',
          label: 'Select images to upload',
          additionalInformation: 'or drag and drop them here',
          allowedFormats: ALLOWED_FILE_EXTENSIONS,
          type: 'file',
          isRequired: true,
          size: 12,
        },
        {
          name: 'condition',
          fieldId: 'condition',
          label: 'Condition(s)',
          helpText:
            'To the best of your ability, please name the condition that is pictured.',
          type: 'input-text',
          isRequired: true,
          size: 7,
        },
        {
          name: 'skinTone',
          fieldId: 'skinTone',
          label: 'Skin Tone',
          helpText:
            'To the best of your ability, choose the closest skin tone to the one in the pictures.',
          type: 'select',
          isImages: true,
          isRequired: true,
          size: 4,
          options: [{ label: 'Select a skin tone', value: '' }, ...SKIN_TONES],
        },
      ],
      {
        name: 'acknowledgeContribution',
        fieldId: 'acknowledgeContribution',
        fieldClass: 'normal',
        label:
          'Please advise whether you wish your contribution to be specifically acknowledged.',
        type: 'radio-group',
        isRequired: true,
        options: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        size: 12,
      },
    ],
  },
  {
    stepNo: 2,
    schema: step3Schema,
    fields: [
      {
        name: 'locations',
        fieldId: 'locations',
        label: 'Location(s)',
        helpText:
          'To the best of your ability, please indicate the location of the condition that is pictured. Check all that apply.',
        type: 'select',
        isRequired: true,
        multiple: true,
        size: 12,
        options: [{ label: 'Select a location', value: '' }, ...LOCATIONS],
      },
      {
        name: 'duration',
        fieldId: 'duration',
        label: 'Duration',
        helpText:
          'At the time of this image, how long had the patient been experiencing this condition?',
        type: 'radio-group',
        isRequired: true,
        size: 12,
        options: DURATIONS,
      },
      {
        name: 'timing',
        fieldId: 'timing',
        label: 'Timing',
        helpText: 'When did the patient most experience pain/discomfort?',
        type: 'radio-group',
        isRequired: true,
        size: 12,
        options: TIMINGS,
      },
      {
        name: 'ageRange',
        fieldId: 'ageRange',
        label: 'Age Range',
        helpText: 'What age range is the patient in?',
        type: 'radio-group',
        isRequired: true,
        size: 12,
        options: AGE_RANGES,
      },
      {
        name: 'modifyingFactors',
        fieldId: 'modifyingFactors',
        label: 'Modifying Factors',
        helpText: 'What did the patient attempt for relief, and did it help?',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
      {
        name: 'healthFactors',
        fieldId: 'healthFactors',
        label: 'Health Factors',
        helpText:
          'Did the patient have any health underlying health conditions?',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
    ],
  },
  {
    stepNo: 3,
    schema: step4Schema(userDetails),
    fields: [
      {
        name: 'patient.symptoms',
        fieldId: 'patient.symptoms',
        label: 'Symptom(s)',
        helpText: 'Please select all of the symptoms the patient experienced',
        type: 'select',
        isRequired: true,
        multiple: true,
        size: 12,
        options: [{ label: 'Select symptoms...', value: '' }, ...SYMPTOMS],
      },
      {
        name: 'patient.painQuality',
        fieldId: 'patient.painQuality',
        label: 'Pain Quality',
        helpText: 'How did the patient describe the pain?',
        type: 'radio-group',
        isRequired: true,
        size: 12,
        options: PAIN_QUALITY,
      },
      {
        name: 'patient.severity',
        fieldId: 'patient.severity',
        label: 'Severity',
        helpText: 'What was the severity of the symptoms?',
        type: 'radio-group',
        isRequired: true,
        size: 12,
        options: SEVERITY,
      },
      {
        name: 'caseBrief',
        fieldId: 'caseBrief',
        label: 'Case Brief',
        helpText:
          'This space is available for you to include a brief summary to provide additional context and ' +
          'insight regarding what is pictured. We encourage you to include any additional information about the image, ' +
          'any care provided or actions taken, and how it may have been resolved in the end.',
        type: 'input-text-area',
        isRequired: true,
        size: 12,
      },
      {
        name: 'signature',
        fieldId: 'signature',
        label: 'Signature',
        helpText:
          'Please enter your first and last name. They must be the same as the ones you gave when registering.',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
    ],
  },
];
