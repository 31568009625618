import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../static/landing-background.jpg';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: `url(${BackgroundImage})`,
    width: '100%',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    boxShadow: 'inset 0 0 0 2000px rgb(0 0 0 / 20%)',
  },
  button: {
    float: 'right',
    fontFamily: 'Avenir !important',
    color: 'white !important',
    fontSize: '20px !important',
    textTransform: 'uppercase',
    marginRight: '50px !important',
    fontWeight: 'bold !important',
  },
  contentBox: {
    margin: 'auto',
    position: 'absolute',
    width: '75%',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%,-45%)',
  },
  title: {
    fontSize: '100px !important',
    color: 'white !important',
    marginBottom: '20px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '75px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '45px !important',
    },
  },
  text: {
    fontSize: '45px !important',
    color: 'white !important',
    marginBottom: '15px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '35px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px !important',
    },
  },
}));

const Landing = () => {
  const styles = useStyles();
  const navigate = useNavigate();

  return (
    <Box className={styles.background}>
      <Box>
        <Button className={styles.button} onClick={() => navigate('/login')}>
          PRACTITIONER LOG IN
        </Button>
      </Box>
      <Box className={styles.contentBox}>
        <Typography variant="h1" className={styles.title}>
          At K&apos;ept Health
        </Typography>
        <Typography variant="h4" className={styles.text}>
          we analyze your skin, health and environment with our inclusive A.I.
          engine and connect you with a board-certified dermatologist for a more
          accurate diagnosis and customized regimen.
        </Typography>
        <Typography variant="h4" className={styles.text}>
          .personalized skin health care starts here.
        </Typography>
      </Box>
    </Box>
  );
};

export default Landing;
