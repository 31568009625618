import {
  Checkbox,
  FormControlLabel,
  Skeleton,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ErrorBox from './ErrorBox';

const CheckBox = ({ field, register, control, errors, isLoading }) =>
  isLoading ? (
    <Skeleton animation="wave" />
  ) : (
    <>
      <FormControlLabel
        control={
          <Controller
            control={control}
            id={field.fieldId}
            className={field.fieldClass}
            name={field.name}
            inputRef={register(field.name)}
            render={({ field: { onChange } }) => (
              <Checkbox
                disabled={field.disabled}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
        }
        label={
          <>
            <Typography
              variant="h5"
              sx={{
                color: '#525252',
              }}
              fontWeight="bold"
              fontSize="16px"
            >
              {field?.label}
              {field.isRequired && <span>*</span>}
            </Typography>
            <Typography variant="body1" component="span">
              {field?.helpText}
            </Typography>
          </>
        }
      />
      <ErrorBox message={_.get(errors, field.name)?.message} />
    </>
  );

CheckBox.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CheckBox;
