import { Typography, Paper, Box, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ReactComponent as FileUploadIcon } from '../../static/icons/file-upload.svg';
import ErrorBox from './ErrorBox';
import ImageSlider from './ImageSlider';

const FileUpload = ({ field, register, control, errors, values, setValue }) => {
  const [files, setFiles] = useState([]);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (values !== undefined) {
      setFiles(values);
    }
  }, [values]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const deleteImage = (index) => {
    setFiles((oldFiles) => oldFiles.filter((item, i) => index !== i));
    setValue(
      field.name,
      values.filter((item, i) => index !== i)
    );
  };

  return (
    <Paper align="center" elevation={0} sx={{ marginBottom: '25px' }}>
      <Grid container>
        <Grid
          item
          xl={files.length > 0 ? 6 : 12}
          lg={files.length > 0 ? 6 : 12}
          md={files.length > 0 ? 6 : 12}
          xs={12}
        >
          <Box
            pt={6}
            pb={6}
            border={
              _.get(errors, field.name)
                ? '1px dashed #C75C57'
                : '1px dashed #A1A1A1'
            }
            backgroundColor="#FAFAFA"
            onClick={handleClick}
            height="100%"
            borderRadius="6px"
            sx={{ cursor: 'pointer' }}
          >
            <FileUploadIcon
              style={{
                fontSize: '100px',
                marginBottom: '40px',
                ...(_.get(errors, field.name)?.message && { fill: '#C75C57' }),
              }}
            />
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 0.5 }}>
              {field.label}
            </Typography>
            {field.additionalInformation && (
              <Typography variant="p" sx={{ color: '#526280' }}>
                {field.additionalInformation}
              </Typography>
            )}
            {field.allowedFormats && (
              <Typography
                variant="h6"
                sx={{ color: '#787878', fontSize: '14px', marginTop: '20px' }}
              >
                FILETYPES ACCEPTED:{' '}
                {field.allowedFormats
                  .map((item) => item.toUpperCase())
                  .join(', ')}
              </Typography>
            )}
            <Box>
              <Controller
                control={control}
                id={field.fieldId}
                defaultValue={[]}
                className={field.fieldClass}
                name={field.name}
                inputRef={register(field.name)}
                render={({ field: { onChange, value, ...rest } }) => (
                  <Box>
                    {typeof value === 'string' && !value?.filename && (
                      <Box pt={2} pb={2}>
                        <Typography>{value}</Typography>
                      </Box>
                    )}
                    <input
                      type="file"
                      disabled={field.disabled}
                      multiple
                      name={field.name}
                      {...rest}
                      style={{ display: 'none' }}
                      value={value?.filename}
                      ref={hiddenFileInput}
                      onChange={(e) => {
                        const newFiles = [
                          ...files,
                          ...Object.values(e.target.files).map((val) => val),
                        ];
                        setFiles(newFiles);
                        hiddenFileInput.current.value = '';
                        return onChange(newFiles);
                      }}
                    />
                  </Box>
                )}
              />
            </Box>
          </Box>
          <ErrorBox message={_.get(errors, field.name)?.message || ''} />
        </Grid>
        {files.length > 0 && (
          <Grid item xl={5} lg={5} md={5} xs={12} sx={{ marginLeft: '20px' }}>
            <Box>
              <ImageSlider
                images={files}
                showDelete={!field.disabled}
                deleteImage={deleteImage}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

FileUpload.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  values: PropTypes.arrayOf(PropTypes.any),
  setValue: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  values: [],
};

export default FileUpload;
