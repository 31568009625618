import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { getUsers } from '../../store/practitioners/actions';
import Table from '../../components/ui/Table';
import SecondaryButton from '../../components/ui/SecondaryButton';
import { capitalizeFirstLetter } from '../../utils/utils';
import { getUser } from '../../store/authentication/actions';
import { Roles } from '../../utils/constants';

const columns = [
  {
    id: 'id',
    label: 'ID',
    props: {
      align: 'center',
    },
  },
  {
    id: 'name',
    label: 'Name and surname',
    value: (row) => `${row.firstName} ${row.lastName}`,
    props: {
      align: 'center',
    },
  },
  {
    id: 'email',
    label: 'Email',
    value: (row) => row.email,
    props: {
      align: 'center',
    },
  },
  {
    id: 'caseImagesCount',
    label: 'Case Images Count',
    value: (row) => row.caseImagesCount,
    props: {
      align: 'center',
    },
  },
  {
    id: 'createdAt',
    label: 'Submitted',
    value: (row) => capitalizeFirstLetter(moment(row.createdAt).fromNow()),
    props: {
      align: 'center',
    },
  },
];

const PractitionerTable = () => {
  const users = useSelector((state) => state.practitioners.data);
  const currentUser = useSelector((state) => state.authentication.user);
  const isLoading = useSelector((state) => state.practitioners.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      dispatch(getUser());
    } else if (currentUser.role !== Roles.ADMIN) {
      navigate('/cases');
    } else if (!users) {
      dispatch(getUsers());
    }
  }, [currentUser, users]);

  return (
    <Box mt={7}>
      <Table
        columns={columns}
        data={users || []}
        isLoading={isLoading}
        header={
          <Box
            padding="15px 10px"
            display="flex"
            justifyContent="space-between"
          >
            <SecondaryButton
              onClick={() => dispatch(getUsers())}
              text="Refresh"
              disabled={isLoading}
              progressProps={{
                sx: { color: 'black' },
              }}
              buttonProps={{
                sx: {
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: '0',
                },
              }}
            />
          </Box>
        }
      />
    </Box>
  );
};

export default PractitionerTable;
