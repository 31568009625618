import { Suspense } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider, Box } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';

import store from './store/store';

import router from './router/Router';
import useWindowDimensions from './utils/hooks/useWindowDimensions';

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: 'Roslindale',
      fontSize: '40px',
      color: 'rgba(36, 36, 36, 1)',
    },
    h2: {
      fontFamily: 'Roslindale',
      fontSize: '28px',
      color: 'rgba(36, 36, 36, 1)',
    },
    h3: {
      fontFamily: 'Roslindale',
      fontSize: '24px',
      color: 'rgba(36, 36, 36, 1)',
    },
    h4: {
      fontFamily: 'Roslindale',
      fontSize: '20px',
      color: 'rgba(36, 36, 36, 1)',
    },
    h5: {
      fontFamily: 'Avenir',
      fontSize: '14px',
      color: 'rgba(36, 36, 36, 1)',
    },
    h6: {
      fontFamily: 'Avenir',
      fontSize: '12px',
      color: 'rgba(36, 36, 36, 1)',
    },
    body1: {
      fontFamily: 'Avenir',
      color: 'rgba(82, 82, 82, 1)',
      fontSize: '14px',
    },
    allVariants: {
      fontFamily: 'Avenir',
      color: '#46505A',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1050,
      lg: 1250,
      xl: 1536,
    },
  },
});

const App = () => {
  const { width } = useWindowDimensions();

  return (
    <Box className="App">
      <ToastContainer
        limit={3}
        position={width < 900 ? 'bottom-center' : 'top-right'}
        autoClose={5000}
        toastClassName="toast"
      />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          <Provider store={store}>
            <Suspense fallback={<div>Loading...</div>}>
              <RouterProvider router={router} />
            </Suspense>
          </Provider>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default App;
