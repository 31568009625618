import { useState } from 'react';

import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import CheckBox from './ui/CheckBox';
import DateField from './ui/DateField';
import FileUpload from './ui/FileUpload';
import Radio from './ui/Radio';
import RadioGroup from './ui/RadioGroup';
import SelectField from './ui/SelectField';
import TextField from './ui/TextField';
import Banner from './ui/Banner';
import FormFieldsArray from './FormFieldsArray';

const FormFields = ({
  page,
  register,
  control,
  errors,
  isLoading,
  renderInputFields,
  getValues,
  setValue,
}) => {
  const [depends, setDepends] = useState(true);
  const { fields } = page;
  let isFirst = false;

  const constructField = (field, renderInput) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <CheckBox
            field={{ ...field, disabled: !renderInput }}
            register={register}
            control={control}
            errors={errors}
            isLoading={isLoading}
          />
        );
      case 'input-radio':
        return (
          <Radio
            controlDepends={setDepends}
            field={{ ...field, disabled: !renderInput }}
            register={register}
            control={control}
            errors={errors}
            isLoading={isLoading}
          />
        );
      case 'input-password':
      case 'input-email':
      case 'input-number':
      case 'input-tel':
      case 'input-url':
      case 'input-text':
      case 'input-text-area':
        return (
          <TextField
            field={{ ...field, disabled: !renderInput }}
            register={register}
            control={control}
            errors={errors}
            isLoading={isLoading}
          />
        );
      case 'input-date':
        return (
          <DateField
            field={{ ...field, disabled: !renderInput }}
            register={register}
            control={control}
            errors={errors}
            isLoading={isLoading}
          />
        );
      case 'select':
        return (
          <SelectField
            field={{ ...field, disabled: !renderInput }}
            register={register}
            control={control}
            errors={errors}
            isLoading={isLoading}
          />
        );
      case 'radio-group':
        return (
          <RadioGroup
            field={{ ...field, disabled: !renderInput }}
            register={register}
            control={control}
            errors={errors}
            isLoading={isLoading}
          />
        );
      case 'file':
        return (
          <FileUpload
            register={register}
            control={control}
            errors={errors}
            field={{ ...field, disabled: !renderInput }}
            values={getValues(field.name)}
            setValue={setValue}
          />
        );
      case 'banner':
        return <Banner field={field} />;
      default:
        throw new Error(`${field.type} is not a valid field type.`);
    }
  };

  return fields?.map((field) => {
    if (field.size === 5 && renderInputFields) {
      isFirst = !isFirst;
    }
    return Array.isArray(field) ? (
      <FormFieldsArray
        page={page}
        control={control}
        fields={field}
        errors={errors}
        constructField={constructField}
        renderInputFields={renderInputFields}
        key={page.multiple.name}
      />
    ) : (
      !(field.hidden && depends) && (
        <Grid
          item
          xs={12}
          sm={12}
          md={field.size}
          lg={field.size}
          xl={field.size}
          key={field.name}
          sx={{
            margin: `15px ${
              field.size === 5 && renderInputFields && !isFirst
                ? '0 0 auto'
                : '0'
            }`,
          }}
        >
          {constructField(field, renderInputFields)}
        </Grid>
      )
    );
  });
};

FormFields.propTypes = {
  page: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
  renderInputFields: PropTypes.bool.isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default FormFields;
