import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const Link = ({ content, to, ...props }) => {
  const navigate = useNavigate();

  return (
    <span
      role="button"
      style={{
        color: '#5372A6',
        textDecoration: 'underline',
        fontFamily: 'Avenir',
        fontSize: '14px',
        cursor: 'pointer',
      }}
      tabIndex={0}
      onClick={() => navigate(to)}
      onKeyDown={() => navigate(to)}
      {...props}
    >
      {content}
    </span>
  );
};

Link.propTypes = {
  content: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default Link;
