import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import PrimaryButton from '../../components/ui/PrimaryButton';
import { tosEULADate } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    padding: '2rem',
    width: '70%',
    margin: '4% auto',
  },
  alignLeft: {
    textAlign: 'left',
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px !important',
    },
  },
  welcomeHead: {
    fontFamily: 'Avenir Black',
    fontWeight: '800 !important',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  welcomeSubHead: {
    textAlign: 'left',
    textTransform: 'uppercase',
    paddingLeft: '1rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: '800 !important',
  },
}));

const Welcome = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector((state) => state.authentication.user);

  return (
    user && (
      <Box className={`${classes.mainBox} ${classes.alignLeft}`}>
        <Box justifyContent="space-between">
          <Box className={classes.gridItem}>
            <Box mb={5}>
              <Typography variant="h2" className={classes.welcomeHead}>
                ALL IN...THE COLLECTIVE
              </Typography>
              <br />
              <Typography>
                K&apos;ept Health image contributors are collectively working to
                improve healthcare outcomes by ensuring that clinicians,
                researchers, and A.I. development engineers have access to a
                diverse set of clinical images.
              </Typography>
            </Box>
            <Box mb={5}>
              <Typography variant="h4" className={classes.welcomeSubHead}>
                ABOUT THE UPLOAD SITE
              </Typography>
              <ul>
                <li>
                  This is where you will upload image(s) and accompanying
                  descriptions into the image directory.
                </li>
                <li>
                  Before you begin, it&apos;ll help to have all available
                  information about the image(s), and to make sure you have
                  secured all rights to the image(s)
                </li>
              </ul>
            </Box>
            <Box mb={5}>
              <Typography variant="h4" className={classes.welcomeSubHead}>
                <span>Requirements</span>
              </Typography>
              <ul>
                <li>
                  The clinical images should be of good quality: in focus,
                  properly exposed, plain background.
                </li>
                <li>
                  The patient should not be identifiable: no full-face pictures
                  are acceptable.
                </li>
                <li>
                  You must have obtained patient consent before providing the
                  image for use in accordance with the Image Upload Terms.
                </li>
                <li>
                  The minimum accepted size for images is 1024×768 pixels, but
                  larger is preferred.
                </li>
              </ul>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', mt: 3 }}>
            <PrimaryButton
              onClick={() =>
                new Date(user?.tosEULAat) < tosEULADate
                  ? navigate('/terms')
                  : navigate('/cases')
              }
              sx={{
                backgroundColor: '#FFD734',
                borderRadius: '0px',
                color: 'white',
                fontWeight: 'bold',
                justifyContent: 'center',
              }}
            >
              Get Started
            </PrimaryButton>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Typography>
              <br />
              Do you have a collection of clinical images? Please email us
              directly at images@kept.health.
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  );
};
export default Welcome;
