import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

export const Accordion = styled(({ children, expanded, onChange }) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    expanded={expanded}
    onChange={onChange}
    sx={{ backgroundColor: 'white' }}
  >
    {children}
  </MuiAccordion>
))(() => ({
  '& .MuiPaper-root': {
    backgroundColor: 'white !important',
  },
  '&': {
    marginBottom: '35px',
    backgroundColor: 'white !important',
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled(({ children }) => (
  <MuiAccordionSummary
    expandIcon={<ArrowDropDownIcon sx={{ color: '#AEBBCE' }} />}
  >
    {children}
  </MuiAccordionSummary>
))(({ theme }) => ({
  background: '#FFFFFF',
  borderLeft: '4px solid #FFB290',
  boxShadow: '0px 12px 30px -8px rgba(62, 87, 254, 0.25)',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '20px',
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
