import { Box, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import Form from '../../../components/Form';
import PrimaryButton from '../../../components/ui/PrimaryButton';
import { formSteps } from './formDetails';
import {
  createCase,
  getCaseById,
  showCaseForm,
  updateCase,
} from '../../../store/cases/actions';
import { getUser } from '../../../store/authentication/actions';
import { Roles } from '../../../utils/constants';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  gridItem: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px !important',
    },
  },
}));

const CaseForm = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const isLoading = useSelector((state) => state.cases.loading);
  const user = useSelector((state) => state.authentication.user);
  const steps = formSteps(user);
  const classes = useStyles();
  const { width } = useWindowDimensions();

  const form = useForm({
    resolver: yupResolver(steps && steps[page].schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (user === null) {
      dispatch(getUser());
    }
  }, [user]);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(getCaseById(id))
        .unwrap()
        .then((data) => {
          Object.keys(data).forEach((key) => {
            if (key === 'patient') {
              Object.keys(data[key]).forEach((innerKey) => {
                form.setValue(`patient.${innerKey}`, data[key][innerKey]);
              });
            } else {
              form.setValue(key, data[key]);
            }
          });
        })
        .catch(() => {
          navigate('/cases');
        });
    }
  }, [id, user]);

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name === 'signature') {
        form.setValue('signatureTimestamp', Date.now());
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const handleNext = async () => {
    if (id !== null) {
      const fieldNames = steps[page].fields
        .map((item) => item.name)
        .filter((i) => i);
      if (steps[page]?.multiple) {
        fieldNames.push(steps[page]?.multiple.name);
      }
      await form.trigger(fieldNames);
    }

    if (id === null || form.formState.isValid || user.role === Roles.ADMIN) {
      if (page + 1 < steps.length) {
        window.scrollTo(0, 0);
        setPage((oldPage) => oldPage + 1);
      } else if (id === undefined) {
        dispatch(createCase(form.getValues()))
          .unwrap()
          .then(() => {
            toast.success('The case has been created.');
            dispatch(showCaseForm(false));
          });
      } else {
        if (user.role === Roles.ADMIN) {
          navigate('/cases');
          return;
        }
        dispatch(updateCase(form.getValues()))
          .unwrap()
          .then(() => {
            toast.success('The case has been updated.');
            navigate('/cases');
          });
      }
    }
  };

  const handleBack = () => {
    if (page - 1 >= 0) {
      setPage((oldPage) => oldPage - 1);
    }
  };

  return (
    steps &&
    user && (
      <Box width="100%" padding={width < 700 ? '3%' : '3% 70px'}>
        <Form
          steps={steps}
          form={form}
          renderInputFields={user.role === Roles.PRACTITIONER}
          startPage={page}
          FormButtons={
            <Grid container justifyContent="space-between" margin="auto">
              {page !== 0 && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classes.gridItem}
                >
                  <PrimaryButton
                    onClick={handleBack}
                    sx={{
                      backgroundColor: '#FFD734',
                      borderRadius: '0px',
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    Back
                  </PrimaryButton>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={page !== 0 ? 6 : 12}
                lg={page !== 0 ? 6 : 12}
                className={classes.gridItem}
              >
                <PrimaryButton
                  onClick={handleNext}
                  sx={{
                    backgroundColor: '#FFD734',
                    borderRadius: '0px',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  disabled={isLoading}
                >
                  {page === steps.length - 1
                    ? user.role === Roles.ADMIN
                      ? 'Finish'
                      : 'Submit'
                    : 'Next'}
                </PrimaryButton>
              </Grid>
            </Grid>
          }
        />
      </Box>
    )
  );
};

export default CaseForm;
