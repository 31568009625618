export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const createFormData = (
  data,
  formData = new FormData(),
  objKey = ''
) => {
  if (Array.isArray(data)) {
    data.forEach((item, index) =>
      createFormData(item, formData, `${objKey}[${index}]`)
    );
  } else if (typeof data === 'object' && !(data instanceof File)) {
    Object.keys(data).map((key) =>
      createFormData(
        data[key],
        formData,
        objKey === '' ? key : `${objKey}[${key}]`
      )
    );
  } else {
    formData.append(objKey, data);
  }
  return formData;
};

export const isPendingAction = (reducerType) => (action) =>
  action.type.includes('pending') && action.type.includes(reducerType);

export const isRejectedAction = (reducerType) => (action) =>
  action.type.includes('rejected') && action.type.includes(reducerType);

export const isFulfilledAction = (reducerType) => (action) =>
  action.type.includes('fulfilled') && action.type.includes(reducerType);

export const isUserAuthenticated = () => {
  const keptHealthData = JSON.parse(window.localStorage.getItem('keptHealth'));
  if (keptHealthData?.accessTokenExpire && keptHealthData?.refreshTokenExpire) {
    return (
      keptHealthData.accessTokenExpire > Math.floor(Date.now() / 1000) ||
      keptHealthData.refreshTokenExpire > Math.floor(Date.now() / 1000)
    );
  }
  return false;
};
