import {
  Skeleton,
  FormLabel,
  FormControl,
  Typography,
  TextField,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ErrorBox from './ErrorBox';

const popperSx = {
  '& .MuiPaper-root': {
    border: '1px solid black',
    padding: 2,
    marginTop: 1,
    backgroundColor: 'rgba(120, 120, 120, 0.2)',
  },
  '& .MuiCalendarPicker-root': {
    backgroundColor: 'rgba(45, 85, 255, 0.4)',
  },
  '& .PrivatePickersSlideTransition-root': {},
  '& .MuiPickersDay-dayWithMargin': {
    color: 'rgb(229,228,226)',
    backgroundColor: 'rgba(50, 136, 153)',
  },
  '& .MuiTabs-root': { backgroundColor: 'rgba(120, 120, 120, 0.4)' },
};

const DateField = ({ field, register, control, errors, isLoading }) => (
  <FormControl component="fieldset" sx={{ minWidth: '100%' }}>
    {isLoading ? (
      <Skeleton animation="wave" />
    ) : (
      <>
        <FormLabel component="legend" sx={{ mb: 2 }}>
          <Typography
            variant="h5"
            sx={{
              color: '#525252',
            }}
            fontWeight="bold"
            fontSize="16px"
          >
            {field?.label}
            {field.isRequired && <span>*</span>}
          </Typography>
          <Typography variant="body1" component="span">
            {field?.helpText}
          </Typography>
        </FormLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            control={control}
            id={field.fieldId}
            className={field.fieldClass}
            name={field.name}
            inputRef={register(field.name)}
            render={({ field: { value, ...others } }) => (
              <DatePicker
                popperPlacement="bottom-start"
                disableFuture
                value={
                  value instanceof Date
                    ? value
                    : new Date(
                        value?.split('-')[0],
                        parseInt(value?.split('-')[1], 10) - 1,
                        value?.split('-')[2]
                      )
                }
                openTo="year"
                disabled={field.disabled}
                views={['year', 'month', 'day']}
                PopperProps={{ sx: popperSx }}
                InputAdornmentProps={{ position: 'start' }}
                name={field.name}
                {...others}
                renderInput={(params) => (
                  <TextField
                    helperText={
                      errors[field.fieldId]
                        ? errors[field.fieldId].message
                        : null
                    }
                    name={field.name}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoComplete="birthday"
                    {...params}
                    placeholder={field.placeholder}
                    error={!!errors[field.fieldId]}
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
        <ErrorBox message={_.get(errors, field.name)?.message} />
      </>
    )}
  </FormControl>
);

DateField.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DateField;
