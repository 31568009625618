import { Box, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import Form from '../../components/Form';
import { login } from '../../store/authentication/actions';
import { formSteps, loginSchema } from './components/formDetails';
import PrimaryButton from '../../components/ui/PrimaryButton';
import Link from '../../components/ui/Link';
import { Roles, tosEULADate } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: '100%',
    padding: '0 70px',
  },
  logInButton: {
    marginBottom: '30px',
    textTransform: 'uppercase',
  },
  gridContainer: {
    justifyContent: 'space-between',
    margin: 'auto',
  },
  alignLeft: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px !important',
    },
  },
}));

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.authentication.loading);
  const classes = useStyles();

  const form = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const handleSubmit = (values) => {
    dispatch(login(values))
      .unwrap()
      .then((data) => {
        if (data.user.role === Roles.ADMIN) {
          navigate('/cases');
          return;
        }

        if (new Date(data?.tosEULAat) < tosEULADate) {
          navigate('/terms');
        } else {
          navigate('/welcome');
        }
      });
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.logInButton}>
        <Typography variant="h3">Log In</Typography>
      </Box>
      <Form
        steps={formSteps}
        form={form}
        onSubmit={handleSubmit}
        FormButtons={
          <Grid container className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className={classes.gridItem}
            >
              <Typography variant="h5" className={classes.alignLeft}>
                Don&apos;t have an account? &nbsp;
                <Link content="Register" to="/register" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classes.gridItem}
            >
              <PrimaryButton
                sx={{
                  backgroundColor: '#FFD734',
                  borderRadius: '0px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                disabled={isLoading}
                type="submit"
              >
                Log In
              </PrimaryButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              className={classes.gridItem}
            >
              <Link content="Forgot your password?" to="/reset-password" />
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default Login;
