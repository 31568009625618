import * as yup from 'yup';

export const codeFormSteps = [
  {
    stepNo: 1,
    fields: [
      {
        name: 'code',
        fieldId: 'code',
        fieldClass: 'normal',
        label: 'Verification Code',
        type: 'input-text',
        size: 12,
      },
    ],
  },
];

export const emailFormSteps = [
  {
    stepNo: 1,
    fields: [
      {
        name: 'email',
        fieldId: 'email',
        fieldClass: 'normal',
        label: 'Email address',
        placeholder: 'Type here...',
        type: 'input-text',
        size: 12,
        inputLabel: 'Email',
      },
    ],
  },
];

export const codeFormSchema = yup
  .object()
  .shape({
    code: yup
      .string('The code is not valid.')
      .required('The verification code is required.')
      .test(
        'len',
        'The code must be exactly 6 characters.',
        (val) => val.length === 6
      ),
  })
  .required();

export const emailFormSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email('Please enter a valid email.')
      .required('Please enter your email here.'),
  })
  .required();
