import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import Form from '../../components/Form';
import { registerUser } from '../../store/authentication/actions';
import { registerFormSteps } from './components/formDetails';
import PrimaryButton from '../../components/ui/PrimaryButton';
import Link from '../../components/ui/Link';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: '100%',
    padding: '0 70px',
  },
  registerBox: {
    marginBottom: '30px',
    textTransform: 'uppercase',
  },
  leftAlign: {
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
  gridItem: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: '15px !important',
    },
  },
}));

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const isLoading = useSelector((state) => state.authentication.loading);
  const classes = useStyles();

  const form = useForm({
    resolver: yupResolver(registerFormSteps[step].schema),
    mode: 'onBlur',
  });

  const handleSubmit = async () => {
    await form.trigger(registerFormSteps[step].fields.map((item) => item.name));
    if (form.formState.isValid) {
      if (step === 0) {
        setStep(1);
      } else {
        dispatch(registerUser(form.getValues()))
          .unwrap()
          .then((data) => {
            toast.success(data?.message);
            navigate('/verification');
          });
      }
    }
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.registerBox}>
        <Typography variant="h3">Register</Typography>
      </Box>
      <Form
        steps={registerFormSteps}
        form={form}
        startPage={step}
        FormButtons={
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className={classes.gridItem}
            >
              <Typography variant="h5" className={classes.leftAlign}>
                Have an account? &nbsp;
                <Link content="Log In" to="/login" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={4}
              className={classes.gridItem}
            >
              {step === 1 && (
                <PrimaryButton
                  onClick={() => setStep(0)}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: '0px',
                    color: '#FFD734',
                    border: '1px solid #FFD734',
                    fontWeight: 'bold',
                  }}
                >
                  Back
                </PrimaryButton>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={4}
              className={classes.gridItem}
            >
              <PrimaryButton
                onClick={handleSubmit}
                sx={{
                  backgroundColor: '#FFD734',
                  borderRadius: '0px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                disabled={isLoading}
              >
                {step === 0 ? 'Next' : 'Sign Up'}
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default Register;
