import { Box, Typography } from '@mui/material';

const Error = () => (
  <Box>
    <Typography variant="h2">
      There has been an error. Please try again.
    </Typography>
  </Box>
);

export default Error;
