import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  getUser,
  login,
  logOut,
  patchUser,
  registerUser,
  resendVerificationCode,
  verifyAccount,
} from './actions';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
  isUserAuthenticated,
} from '../../utils/utils';

const initialState = {
  loading: false,
  user: null,
  data: null,
  error: null,
  isAuthenticated: isUserAuthenticated(),
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, { payload }) => {
        window.localStorage.setItem(
          'keptHealth',
          JSON.stringify({
            accessTokenExpire: payload.accessTokenExpire,
            refreshTokenExpire: payload.refreshTokenExpire,
          })
        );
        return {
          ...state,
          user: payload.user,
          error: null,
          isAuthenticated: true,
        };
      })

      .addCase(getUser.fulfilled, (state, { payload }) => ({
        ...state,
        user: payload,
        error: null,
      }))

      .addCase(patchUser.fulfilled, (state, { payload }) => ({
        ...state,
        user: payload,
        error: null,
      }))

      .addCase(logOut.fulfilled, (state, { payload }) => {
        window.localStorage.removeItem('keptHealth');
        return {
          ...state,
          user: payload,
          error: null,
        };
      })

      .addMatcher(
        isAnyOf(
          registerUser.fulfilled,
          resendVerificationCode.fulfilled,
          verifyAccount.fulfilled,
          logOut.fulfilled
        ),
        (state, { payload }) => ({
          ...state,
          loading: false,
          data: payload,
          error: null,
          isAuthenticated: false,
        })
      )

      .addMatcher(isPendingAction('authentication'), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction('authentication'), (state, { payload }) => ({
        ...state,
        loading: false,
        error: payload,
        data: null,
        isAuthenticated: false,
      }))
      .addMatcher(isFulfilledAction('authentication'), (state) => {
        state.loading = false;
      });
  },
});

export default authenticationSlice.reducer;
