import * as yup from 'yup';

export const page1Schema = yup
  .object()
  .shape({
    email: yup
      .string('The email is not valid.')
      .email('Please enter a valid email.')
      .required('Please enter your email.'),
    password: yup
      .string()
      .required('Please enter your password.')
      .min(8, 'The password must be at least 8 characters long.')
      .matches(
        /[a-z]+/,
        'The password must contain at least one lowercase character.'
      )
      .matches(
        /[A-Z]+/,
        'The password must contain at least one uppercase character.'
      )
      .matches(/\d+/, 'The password must contain at least one number.')
      .matches(
        /[@$!%*#?&]+/,
        'The password must contain at least one special character.'
      ),
  })
  .required();

const page2Schema = yup
  .object()
  .shape({
    firstName: yup
      .string('The first name is not valid.')
      .trim()
      .required('Please enter your first name.')
      .min(2, 'The first name must be at least 2 characters long.')
      .matches(
        /[a-zA-Z\s]+/,
        'The first name must only contain letters and spaces.'
      ),
    lastName: yup
      .string('The last name is not valid.')
      .trim()
      .required('Please enter your last name.')
      .min(2, 'The last name must be at least 2 characters long.')
      .matches(
        /[a-zA-Z\s]+/,
        'The last name must only contain letters and spaces.'
      ),
    licenseNumber: yup
      .string('The license number is not valid.')
      .trim()
      .min(6, 'The license number must be greater than 6 characters.')
      .max(10, 'The license number must be less than 10 characters. ')
      .required('Please enter your license number.'),
    address: yup.object().shape({
      state: yup
        .string('The state is not valid.')
        .trim()
        .required('Please enter your state.')
        .min(4, 'The state must be at least 4 characters long.'),
      city: yup
        .string('The city is not valid.')
        .trim()
        .required('Please enter your city.'),
      address: yup
        .string('The address is not valid.')
        .trim()
        .required('Please enter your address.'),
      zipCode: yup
        .string('The zip code is not valid.')
        .trim()
        .min(5, 'The ZIP code must be at least 5 characters long.')
        .required('Please enter your zip code.')
        .matches(/[0-9]+/, 'The zip code must only contain numbers.'),
    }),
  })
  .required();

export const registerFormSteps = [
  {
    stepNo: 1,
    schema: page1Schema,
    fields: [
      {
        name: 'email',
        fieldId: 'email',
        label: 'Email address',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
      {
        name: 'password',
        fieldId: 'password',
        fieldClass: 'normal',
        label: 'Password',
        type: 'input-password',
        isRequired: true,
        size: 12,
      },
    ],
  },
  {
    stepNo: 2,
    schema: page2Schema,
    fields: [
      {
        name: 'firstName',
        fieldId: 'firstName',
        label: 'First name',
        type: 'input-text',
        isRequired: true,
        size: 5,
      },
      {
        name: 'lastName',
        fieldId: 'lastName',
        label: 'Last name',
        type: 'input-text',
        isRequired: true,
        size: 5,
      },
      {
        name: 'licenseNumber',
        fieldId: 'licenseNumber',
        label: 'License Number',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
      {
        name: 'address.state',
        fieldId: 'state',
        label: 'State',
        type: 'input-text',
        isRequired: true,
        size: 5,
      },
      {
        name: 'address.city',
        fieldId: 'city',
        label: 'City',
        type: 'input-text',
        isRequired: true,
        size: 5,
      },
      {
        name: 'address.address',
        fieldId: 'address',
        label: 'Address',
        type: 'input-text',
        isRequired: true,
        size: 5,
      },
      {
        name: 'address.zipCode',
        fieldId: 'zipCode',
        label: 'Zip Code',
        type: 'input-text',
        isRequired: true,
        size: 5,
      },
    ],
  },
];
