import * as yup from 'yup';

export const consentSchema = (userDetails) =>
  yup.object().shape({
    tos: yup
      .bool()
      .default(false)
      .required('You must accept the terms of service.')
      .oneOf([true], 'You must accept the terms of service.'),
    grant: yup
      .bool()
      .default(false)
      .required('You must grant all rights.')
      .oneOf([true], 'You must grant all rights.'),
    years: yup
      .bool()
      .default(false)
      .required('You must agree to the duration of the data usage.')
      .oneOf([true], 'You must agree to the duration of the data usage.'),
    release: yup
      .bool()
      .default(false)
      .oneOf([true], 'You must consent to the release.'),
    rights: yup
      .bool()
      .default(false)
      .required('You must properly hanle rights.')
      .oneOf([true], 'You must grant all rights.'),
    signature: yup
      .string('The signature is not valid.')
      .required('The signature is required.')
      .test(
        'check-signature',
        userDetails
          ? `The signature must match ${userDetails.firstName} ${userDetails.lastName}.`
          : 'The signature is not valid.',
        (val) =>
          userDetails &&
          val === `${userDetails.firstName} ${userDetails.lastName}`
      ),
  });

export const formSteps = [
  {
    stepNo: 1,
    fields: [
      {
        name: 'tos',
        fieldId: 'tos',
        label: 'I accept the Terms of Service.',
        type: 'checkbox',
        isRequired: true,
        size: 12,
      },
    ],
  },
  {
    stepNo: 2,
    fields: [
      {
        name: 'grant',
        fieldId: 'grant',
        label:
          'I grant all rights to use and display the uploaded images and associated data.',
        type: 'checkbox',
        isRequired: true,
        size: 12,
      },
      {
        name: 'years',
        fieldId: 'years',
        label:
          'I understand that the images can be used for up to 12 years unless I provide written notice.',
        type: 'checkbox',
        isRequired: true,
        size: 12,
      },
      {
        name: 'release',
        fieldId: 'release',
        label:
          'I release Well-Kept, Inc. From any and all possible legal reprecussions from using the images I provide.',
        type: 'checkbox',
        isRequired: true,
        size: 12,
      },
      {
        name: 'rights',
        fieldId: 'rights',
        label:
          'I confirm that I own the rights or have obtained the necessary permissions for my uploaded images.',
        type: 'checkbox',
        isRequired: true,
        size: 12,
      },
      {
        name: 'signature',
        fieldId: 'signature',
        label: 'Signature',
        helpText:
          'Please enter your first and last name. They must be the same as the ones used when registering.',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
    ],
  },
];
