import * as yup from 'yup';

export const formSteps = [
  {
    stepNo: 1,
    fields: [
      {
        name: 'email',
        fieldId: 'email',
        label: 'Email address',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
      {
        name: 'password',
        fieldId: 'password',
        label: 'Password',
        isRequired: true,
        type: 'input-password',
        size: 12,
      },
    ],
  },
];

export const loginSchema = yup
  .object()
  .shape({
    email: yup
      .string('The email is not valid.')
      .email('Please enter a valid email.')
      .required('Please enter your email.'),
    password: yup
      .string('The password is not valid.')
      .required('Please enter your password.')
      .min(8, 'The password must be at least 8 characters long.')
      .matches(
        /[a-z]+/,
        'The password must contain at least one lowercase character.'
      )
      .matches(
        /[A-Z]+/,
        'The password must contain at least one uppercase character.'
      )
      .matches(/\d+/, 'The password must contain at least one number.'),
  })
  .required();
