import CollectionsIcon from '@mui/icons-material/Collections';

import { ReactComponent as OpenFolder } from '../static/icons/open-folder.svg';
import SkinTone1 from '../static/skin-tones/type-1.png';
import SkinTone2 from '../static/skin-tones/type-2.png';
import SkinTone3 from '../static/skin-tones/type-3.png';
import SkinTone4 from '../static/skin-tones/type-4.png';
import SkinTone5 from '../static/skin-tones/type-5.png';
import SkinTone6 from '../static/skin-tones/type-6.png';

export const ALLOWED_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'rar', 'zip'];

export const tosEULADate = new Date('2023/01/01');

export const Roles = {
  ADMIN: 'ADMIN',
  PRACTITIONER: 'PRACTITIONER',
};

export const MenuItems = [
  {
    title: 'My Cases',
    icon: OpenFolder,
    endpoint: '/cases',
  },
  {
    title: 'Gallery',
    icon: CollectionsIcon,
    endpoint: '/gallery',
  },
  {
    title: 'Practitioners',
    icon: CollectionsIcon,
    role: Roles.ADMIN,
    endpoint: '/practitioners',
  },
  {
    title: 'Sign Out',
    icon: OpenFolder,
  },
];

export const DURATIONS = [
  { label: 'Up to 1 day', value: 'UP_TO_ONE_DAY' },
  { label: '1-2 Days', value: 'ONE_TWO_DAYS' },
  { label: '3-4 Days', value: 'THREE_FOUR_DAYS' },
  { label: '5-7 Days', value: 'FIVE_SEVEN_DAYS' },
  { label: '1-2 Weeks', value: 'ONE_TWO_WEEKS' },
  { label: '2-3 Weeks', value: 'TWO_THREE_WEEKS' },
  { label: '3-4 Weeks', value: 'THREE_FOUR_WEEKS' },
  { label: 'More than 1 month', value: 'MORE_ONE_MONTH' },
  { label: 'Long term', value: 'LONG_TERM' },
  { label: 'N/A', value: 'N_A' },
];

export const TIMINGS = [
  { label: 'Intermittent', value: 'INTERMITTENT' },
  { label: 'Ongoing', value: 'ONGOING' },
  { label: 'Rarely', value: 'RARELY' },
  { label: 'Upon trigger', value: 'UPON_TRIGGER' },
];

export const LOCATIONS = [
  { label: 'Ankle - Left', value: 'ANKLE_LEFT' },
  { label: 'Ankle - Right', value: 'ANKLE_RIGHT' },
  { label: 'Anus', value: 'ANUS' },
  { label: 'Arm - Lower Left', value: 'ARM_LOWER_LEFT' },
  { label: 'Arm - Lower Right', value: 'ARM_LOWER_RIGHT' },
  { label: 'Arm - Upper Left', value: 'ARM_UPPER_LEFT' },
  { label: 'Arm - Upper Right', value: 'ARM_UPPER_RIGHT' },
  { label: 'Back - Lower', value: 'BACK_LOWER' },
  { label: 'Back - Upper', value: 'BACK_UPPER' },
  { label: 'Belly Button', value: 'BELLY_BUTTON' },
  { label: 'Buttocks', value: 'BUTTOCKS' },
  { label: 'Cheek - Left', value: 'CHEEK_LEFT' },
  { label: 'Cheek - Right', value: 'CHEEK_RIGHT' },
  { label: 'Chest', value: 'CHEST' },
  { label: 'Chin', value: 'CHIN' },
  { label: 'Ear - Left', value: 'EAR_LEFT' },
  { label: 'Ear - Right', value: 'EAR_RIGHT' },
  {
    label: 'Elbow - Anterior Left',
    value: 'ELBOW_ANTERIOR_LEFT',
  },
  {
    label: 'Elbow - Anterior Right',
    value: 'ELBOW_ANTERIOR_RIGHT',
  },
  {
    label: 'Elbow - Posterior Left',
    value: 'ELBOW_POSTERIOR_LEFT',
  },
  {
    label: 'Elbow - Posterior Right',
    value: 'ELBOW_POSTERIOR_RIGHT',
  },
  {
    label: 'Eyelid - Left',
    value: 'EYELID_LEFT',
  },
  {
    label: 'Eyelid - Right',
    value: 'EYELID_RIGHT',
  },
  {
    label: 'Face',
    value: 'FACE',
  },
  {
    label: 'Finger - Index (2nd) Left',
    value: 'FINGER_INDEX_2ND_LEFT',
  },
  {
    label: 'Finger - Index (2nd) Right',
    value: 'FINGER_INDEX_2ND_RIGHT',
  },
  {
    label: 'Finger - Middle (3rd) Left',
    value: 'FINGER_MIDDLE_3RD_LEFT',
  },
  {
    label: 'Finger - Middle (3rd) Right',
    value: 'FINGER_MIDDLE_3RD_RIGHT',
  },
  {
    label: 'Finger - Pinky (5th) Left',
    value: 'FINGER_PINKY_5TH_LEFT',
  },
  {
    label: 'Finger - Pinky (5th) Right',
    value: 'FINGER_PINKY_5TH_RIGHT',
  },
  {
    label: 'Finger - Ring (4th) Left',
    value: 'FINGER_RING_4TH_LEFT',
  },
  {
    label: 'Finger - Ring (4th) Right',
    value: 'FINGER_RING_4TH_RIGHT',
  },
  {
    label: 'Finger - Thumb Left',
    value: 'FINGER_THUMB_LEFT',
  },
  {
    label: 'Finger - Thumb Right',
    value: 'FINGER_THUMB_RIGHT',
  },
  {
    label: 'Finger Nail - Index (2nd) Left',
    value: 'FINGER_NAIL_INDEX_2ND_LEFT',
  },
  {
    label: 'Finger Nail - Index (2nd) Right',
    value: 'FINGER_NAIL_INDEX_2ND_RIGHT',
  },
  {
    label: 'Finger Nail - Middle (3rd) Left',
    value: 'FINGER_NAIL_MIDDLE_3RD_LEFT',
  },
  {
    label: 'Finger Nail - Middle (3rd) Right',
    value: 'FINGER_NAIL_MIDDLE_3RD_RIGHT',
  },
  {
    label: 'Finger Nail - Pinky (5th) Left',
    value: 'FINGER_NAIL_PINKY_5TH_LEFT',
  },
  {
    label: 'Finger Nail - Pinky (5th) Right',
    value: 'FINGER_NAIL_PINKY_5TH_RIGHT',
  },
  {
    label: 'Finger Nail - Ring (4th) Left',
    value: 'FINGER_NAIL_RING_4TH_LEFT',
  },
  {
    label: 'Finger Nail - Ring (4th) Right',
    value: 'FINGER_NAIL_RING_4TH_RIGHT',
  },
  {
    label: 'Finger Nail - Thumb Left',
    value: 'FINGER_NAIL_THUMB_LEFT',
  },
  {
    label: 'Finger Nail - Thumb Right',
    value: 'FINGER_NAIL_THUMB_RIGHT',
  },
  {
    label: 'Foot - Between Toes',
    value: 'FOOT_BETWEEN_TOES',
  },
  {
    label: 'Foot - Sole Left',
    value: 'FOOT_SOLE_LEFT',
  },
  {
    label: 'Foot - Sole Right',
    value: 'FOOT_SOLE_RIGHT',
  },
  {
    label: 'Foot - Top Left',
    value: 'FOOT_TOP_LEFT',
  },
  {
    label: 'Foot - Top Right',
    value: 'FOOT_TOP_RIGHT',
  },
  {
    label: 'Forehead',
    value: 'FOREHEAD',
  },
  {
    label: 'Groin',
    value: 'GROIN',
  },
  {
    label: 'Hand - Back Left',
    value: 'HAND_BACK_LEFT',
  },
  {
    label: 'Hand - Back Right',
    value: 'HAND_BACK_RIGHT',
  },
  {
    label: 'Hand - Palm Left',
    value: 'HAND_PALM_LEFT',
  },
  {
    label: 'Hand - Palm Right',
    value: 'HAND_PALM_RIGHT',
  },
  {
    label: 'Knee - Back Left',
    value: 'KNEE_BACK_LEFT',
  },
  {
    label: 'Knee - Back Right',
    value: 'KNEE_BACK_RIGHT',
  },
  {
    label: 'Knee - Front Left',
    value: 'KNEE_FRONT_LEFT',
  },
  {
    label: 'Knee - Front Right',
    value: 'KNEE_FRONT_RIGHT',
  },
  {
    label: 'Leg - Lower Left',
    value: 'LEG_LOWER_LEFT',
  },
  {
    label: 'Leg - Lower Right',
    value: 'LEG_LOWER_RIGHT',
  },
  {
    label: 'Leg - Upper Left',
    value: 'LEG_UPPER_LEFT',
  },
  {
    label: 'Leg - Upper Right',
    value: 'LEG_UPPER_RIGHT',
  },
  {
    label: 'Lips',
    value: 'LIPS',
  },
  {
    label: 'Mouth',
    value: 'MOUTH',
  },
  {
    label: 'Neck - Back',
    value: 'NECK_BACK',
  },
  {
    label: 'Neck - Front',
    value: 'NECK_FRONT',
  },
  {
    label: 'Nipple - Left',
    value: 'NIPPLE_LEFT',
  },
  {
    label: 'Nipple - Right',
    value: 'NIPPLE_RIGHT',
  },
  {
    label: 'Nose',
    value: 'NOSE',
  },
  {
    label: 'Oral Mucosa',
    value: 'ORAL_MUCOSA',
  },
  {
    label: 'Pubic Area',
    value: 'PUBIC_AREA',
  },
  {
    label: 'Pubic Area - Labia Majora',
    value: 'PUBIC_AREA_LABIA_MAJORA',
  },
  {
    label: 'Pubic Area - Labia Minora',
    value: 'PUBIC_AREA_LABIA_MINORA',
  },
  {
    label: 'Pubic Area - Mons Pubis',
    value: 'PUBIC_AREA_MONS_PUBIS',
  },
  {
    label: 'Scalp',
    value: 'SCALP',
  },
  {
    label: 'Shoulder - Left',
    value: 'SHOULDER_LEFT',
  },
  {
    label: 'Shoulder - Right',
    value: 'SHOULDER_RIGHT',
  },
  {
    label: 'Toe 2nd - Left',
    value: 'TOE_2ND_LEFT',
  },
  {
    label: 'Toe 2nd - Right',
    value: 'TOE_2ND_RIGHT',
  },
  {
    label: 'Toe 3rd - Left',
    value: 'TOE_3RD_LEFT',
  },
  {
    label: 'Toe 3rd - Right',
    value: 'TOE_3RD_RIGHT',
  },
  {
    label: 'Toe 4th - Left',
    value: 'TOE_4TH_LEFT',
  },
  {
    label: 'Toe 4th - Right',
    value: 'TOE_4TH_RIGHT',
  },
  {
    label: 'Toe 5th - Left',
    value: 'TOE_5TH_LEFT',
  },
  {
    label: 'Toe 5th - Right',
    value: 'TOE_5TH_RIGHT',
  },
  {
    label: 'Toe Big - Left',
    value: 'TOE_BIG_LEFT',
  },
  {
    label: 'Toe Big - Right',
    value: 'TOE_BIG_RIGHT',
  },
  {
    label: 'Toenail 2nd - Left',
    value: 'TOENAIL_2ND_LEFT',
  },
  {
    label: 'Toenail 2nd - Right',
    value: 'TOENAIL_2ND_RIGHT',
  },
  {
    label: 'Toenail 3rd - Left',
    value: 'TOENAIL_3RD_LEFT',
  },
  {
    label: 'Toenail 3rd - Right',
    value: 'TOENAIL_3RD_RIGHT',
  },
  {
    label: 'Toenail 4th - Left',
    value: 'TOENAIL_4TH_LEFT',
  },
  {
    label: 'Toenail 4th - Right',
    value: 'TOENAIL_4TH_RIGHT',
  },
  {
    label: 'Toenail 5th - Left',
    value: 'TOENAIL_5TH_LEFT',
  },
  {
    label: 'Toenail 5th - Right',
    value: 'TOENAIL_5TH_RIGHT',
  },
  {
    label: 'Toenail Big - Left',
    value: 'TOENAIL_BIG_LEFT',
  },
  {
    label: 'Toenail Big - Right',
    value: 'TOENAIL_BIG_RIGHT',
  },
];

export const SYMPTOMS = [
  { label: 'Burning', value: 'BURNING' },
  { label: 'Itching', value: 'ITCHING' },
  { label: 'Painful', value: 'PAINFUL' },
  { label: 'Redness', value: 'REDNESS' },
  { label: 'Swelling', value: 'SWELLING' },
  { label: 'Tender', value: 'TENDER' },
  { label: 'Other', value: 'OTHER' },
  { label: 'None', value: 'NONE' },
];

export const PAIN_QUALITY = [
  { label: 'Sharp', value: 'SHARP' },
  { label: 'Dull', value: 'DULL' },
  { label: 'Throbbing', value: 'THROBBING' },
  { label: 'Radiating', value: 'RADIATING' },
  { label: 'Aching', value: 'ACHING' },
  { label: 'None', value: 'NONE' },
];

export const SEVERITY = [
  { label: 'Low', value: 'LOW' },
  { label: 'Mild', value: 'MILD' },
  { label: 'Moderate', value: 'MODERATE' },
  { label: 'Severe', value: 'SEVERE' },
  { label: 'Highly Severe ', value: 'HIGHLY_SEVERE' },
];

export const SKIN_TONES = [
  { label: SkinTone1, value: 'SKIN_TONE_1', visibleLabel: 'Skin Tone Type 1' },
  { label: SkinTone2, value: 'SKIN_TONE_2', visibleLabel: 'Skin Tone Type 2' },
  { label: SkinTone3, value: 'SKIN_TONE_3', visibleLabel: 'Skin Tone Type 3' },
  { label: SkinTone4, value: 'SKIN_TONE_4', visibleLabel: 'Skin Tone Type 4' },
  { label: SkinTone5, value: 'SKIN_TONE_5', visibleLabel: 'Skin Tone Type 5' },
  { label: SkinTone6, value: 'SKIN_TONE_6', visibleLabel: 'Skin Tone Type 6' },
];

export const AGE_RANGES = [
  { label: '0-12', value: '0-12' },
  { label: '13-18', value: '13-18' },
  { label: '19-29', value: '19-29' },
  { label: '30-49', value: '30-49' },
  { label: '50-64', value: '50-64' },
  { label: '65+', value: '65+' },
];
