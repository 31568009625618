import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getCaseFileById, updateCaseFile } from '../../../store/files/actions';
import Form from '../../../components/Form';
import PrimaryButton from '../../../components/ui/PrimaryButton';
import { caseFileConditionSchema, formSteps } from './formDetails';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import { Roles } from '../../../utils/constants';

const useStyles = makeStyles(() => ({
  mainBox: {
    padding: '0 40px 25px !important',
  },
  dialogTitle: {
    textAlign: 'center',
    marginTop: '10px !important',
    fontSize: '24px !important',
    fontFamily: 'Roslindale !important',
  },
}));

const ImageDetailsDialog = ({ setOpen, caseFileId, selectedFile }) => {
  const [caseFiles, setCaseFiles] = useState({
    files: [],
    loading: true,
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const containerRef = useRef();
  const { width } = useWindowDimensions();
  const user = useSelector((state) => state.authentication.user);

  const form = useForm({
    resolver: yupResolver(caseFileConditionSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (caseFileId) {
      dispatch(getCaseFileById({ caseFileId }))
        .unwrap()
        .then((data) => {
          setCaseFiles({
            loading: false,
            files: data.files.filter(
              (file) => file.fileId !== selectedFile.fileId
            ),
          });
          form.setValue('condition', data.condition);
          form.setValue('caseFileId', caseFileId);
          form.setValue('caseId', data.caseId);
        });
    }
  }, [caseFileId]);

  const handleSubmit = (values) => {
    dispatch(updateCaseFile(values))
      .unwrap()
      .then(() => {
        toast.success('The case file has been updated.');
        setOpen(false);
      });
  };

  const scrollSlides = (direction) => {
    const currWidth = containerRef.current?.clientWidth;

    const diff = direction === 'next' ? 1 : -1;
    const newValue =
      (clickCount + diff) % (caseFiles.files.length / (width < 700 ? 1 : 4));
    setClickCount(newValue);
    const scrollTo = currWidth * newValue;

    containerRef.current?.scrollTo({
      behavior: 'smooth',
      left: scrollTo,
    });
  };

  return (
    !!caseFileId &&
    !!selectedFile && (
      <>
        <DialogTitle className={classes.dialogTitle}>
          Edit a Case File
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.mainBox}>
          <Collapse in={alertOpen}>
            {!caseFiles.loading && (
              <Alert
                severity={
                  user.role === Roles.PRACTITIONER && caseFiles.files.length > 0
                    ? 'warning'
                    : 'success'
                }
                sx={{
                  width: 'fit-content',
                  margin: 'auto',
                  padding: '15px 40px',
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlertOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {caseFiles.files.length > 0 ? (
                  <>
                    <AlertTitle sx={{ fontSize: '16px' }}>
                      {user.role === Roles.PRACTITIONER
                        ? 'By changing the condition of {selectedFile.name}, the following images will also be affected:'
                        : 'The following images are attached to the same case file:'}
                    </AlertTitle>
                    <Box mt={2}>
                      {width >= 600 && caseFiles.files.length > 4 && (
                        <IconButton
                          sx={{
                            float: 'left',
                            width: '3%',
                            verticalAlign: 'middle',
                            height: '100px',
                            paddingLeft: '10px',
                          }}
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => scrollSlides('prev')}
                        >
                          <ArrowBackIosIcon />
                        </IconButton>
                      )}
                      <Box
                        sx={{
                          width: '90%',
                          height: '100px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          float: 'left',
                        }}
                        ref={containerRef}
                      >
                        {caseFiles.files.map((item) => (
                          <a
                            href={item.originalFileUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              key={item.thumbnailFileUrl}
                              src={item.thumbnailFileUrl}
                              alt="case file images"
                              style={{
                                display: 'inline-block',
                                paddingRight: '5px',
                                width: width < 700 ? '100%' : '25%',
                                height: '100px',
                              }}
                            />
                          </a>
                        ))}
                      </Box>
                      {width >= 600 && caseFiles.files.length > 4 && (
                        <IconButton
                          sx={{
                            float: 'left',
                            width: '3%',
                            verticalAlign: 'middle',
                            height: '100px',
                          }}
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => scrollSlides('next')}
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      )}
                      {width < 600 && caseFiles.files.length > 4 && (
                        <Box>
                          <Grid container>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <IconButton
                                sx={{ float: 'right' }}
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => scrollSlides('prev')}
                              >
                                <ArrowBackIosIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => scrollSlides('next')}
                              >
                                <ArrowForwardIosIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  </>
                ) : (
                  <AlertTitle>
                    {user.role === Roles.PRACTITIONER
                      ? 'No other images will be affected by this change.'
                      : 'There are no other images attached to this case file.'}
                  </AlertTitle>
                )}
              </Alert>
            )}
          </Collapse>
          <Box sx={{ mt: 4 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ textAlign: 'center' }}
              >
                <img
                  src={selectedFile.thumbnailFileUrl}
                  alt={`changing file ${selectedFile.name}`}
                  style={{
                    width: '100%',
                    margin: 'auto',
                    textAlign: 'center',
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                sx={{ textAlign: 'center' }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <Box width="100%" mb={1} mt={1}>
                    <Typography variant="h4" sx={{ fontSize: '17px' }}>
                      File Name: {selectedFile.name}
                    </Typography>
                  </Box>
                  <Box width="100%" p={2}>
                    {user.role === Roles.PRACTITIONER ? (
                      <Form
                        steps={formSteps}
                        onSubmit={handleSubmit}
                        form={form}
                        FormButtons={
                          <Box>
                            <PrimaryButton
                              sx={{
                                backgroundColor: '#FFD734',
                                borderRadius: '0px',
                                color: 'white',
                                fontWeight: 'bold',
                              }}
                              type="submit"
                            >
                              Submit
                            </PrimaryButton>
                          </Box>
                        }
                      />
                    ) : (
                      <Box mt={2} width="100%">
                        <a
                          href={selectedFile.originalFileUrl}
                          target="_blank"
                          rel="noreferrer"
                          style={{ textDecoration: 'none' }}
                        >
                          <PrimaryButton
                            sx={{
                              backgroundColor: '#FFD734',
                              borderRadius: '0px',
                              color: 'white',
                              fontWeight: 'bold',
                            }}
                          >
                            Open in new tab{' '}
                            <OpenInNewIcon sx={{ paddingLeft: '5px' }} />
                          </PrimaryButton>
                        </a>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </>
    )
  );
};

ImageDetailsDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  caseFileId: PropTypes.string,
  selectedFile: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      thumbnailFileUrl: PropTypes.string.isRequired,
      originalFileUrl: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired,
    })
  ),
};

ImageDetailsDialog.defaultProps = {
  caseFileId: null,
  selectedFile: null,
};

export default ImageDetailsDialog;
