import * as yup from 'yup';

export const formSteps = [
  {
    stepNo: 1,
    fields: [
      {
        name: 'email',
        fieldId: 'email',
        label: 'Email address',
        type: 'input-text',
        size: 12,
      },
    ],
  },
];

export const resetPasswordSchema = yup
  .object()
  .shape({
    email: yup
      .string('The email is not valid.')
      .email('Please enter a valid email.')
      .required('Please enter your email.'),
  })
  .required();
