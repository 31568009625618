import React, { useEffect, useRef, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

import { ReactComponent as Logo } from '../../static/kept-yellow-logo.svg';
import SidepanelImage from '../../static/sidepanel-image.jpg';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    top: '0',
    position: 'fixed',
    right: '0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  image: {
    width: '100%',
    height: '100%',
  },
  mainBox: {
    height: '100vh',
    overflow: 'auto',
  },
  gridContainer: {
    alignItems: 'center',
    height: '100%',
  },
  gridItem: {
    height: '100%',
  },
}));

const DefaultLayout = () => {
  const navigate = useNavigate();
  const logoBoxRef = useRef();
  const classes = useStyles();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [logoHeight, setLogoHeight] = useState(100);
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  useEffect(() => {
    if (logoBoxRef?.current?.offsetHeight) {
      setLogoHeight(logoBoxRef.current.offsetHeight);
    }
  }, [logoBoxRef]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/cases');
    }
  }, [isAuthenticated]);

  return (
    <Box className={classes.mainBox}>
      {location.pathname !== '/' ? (
        <Grid container className={classes.gridContainer}>
          <Grid
            item
            xs={12}
            sm={8}
            md={7}
            lg={7}
            xl={7}
            className={classes.gridItem}
          >
            <Box height="100%">
              <Box ref={logoBoxRef}>
                <Logo
                  style={{ display: 'block' }}
                  onClick={() => navigate('/login')}
                />
              </Box>
              <Box sx={width > 900 && { paddingTop: `${logoHeight}px` }}>
                <Outlet />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={5}
            lg={5}
            xl={5}
            className={classes.root}
          >
            <img
              className={classes.image}
              src={SidepanelImage}
              alt="Kept Health side panel"
            />
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Outlet />
        </Box>
      )}
    </Box>
  );
};

export default DefaultLayout;
