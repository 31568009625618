import { createSlice } from '@reduxjs/toolkit';

import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../../utils/utils';
import { getPaginatedFiles } from './actions';

const initialState = {
  loading: false,
  data: {
    totalCount: null,
    files: null,
  },
  error: null,
};

export const filesSlice = createSlice({
  name: 'files',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getPaginatedFiles.fulfilled, (state, { payload }) => {
        state.data.totalCount = payload.totalCount;
        state.data.files = [...(state?.data?.files || []), ...payload.files]
          .filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id)
          )
          .sort((a, b) => a - b);
      })

      .addMatcher(isPendingAction('files'), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction('files'), (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addMatcher(isFulfilledAction('files'), (state) => {
        state.loading = false;
      });
  },
});

export default filesSlice.reducer;
