import { createSlice } from '@reduxjs/toolkit';

import { createCase, getCaseById, getCases, showCaseForm } from './actions';
import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../../utils/utils';

const initialState = {
  loading: false,
  cases: null,
  error: null,
  currentComponent: null,
  showCaseForm: false,
};

export const casesSlice = createSlice({
  name: 'cases',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(showCaseForm, (state, { payload }) => {
        state.showCaseForm = payload;
      })

      .addCase(getCases.fulfilled, (state, { payload }) => {
        state.cases = payload;
      })

      .addCase(getCaseById.fulfilled, (state, { payload }) => {
        if (state.cases === null) {
          state.cases = [payload];
        } else {
          state.cases = state.cases.map((item) => {
            if (item.id === payload.id) {
              return payload;
            }
            return item;
          });
        }
      })

      .addCase(createCase.fulfilled, (state, { payload }) => {
        state.cases = [payload, ...state.cases];
      })

      .addMatcher(isPendingAction('cases'), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction('cases'), (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addMatcher(isFulfilledAction('cases'), (state) => {
        state.loading = false;
      });
  },
});

export default casesSlice.reducer;
