import { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import { consentSchema, formSteps } from './components/formDetails';
import Form from '../../components/Form';
import PrimaryButton from '../../components/ui/PrimaryButton';
import { patchUser } from '../../store/authentication/actions';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    padding: '30px',
    paddingLeft: '100px',
    width: '70%',
    margin: '5% auto 0',
  },
  gridContainer: {
    justifyContent: 'space-between',
    margin: 'auto',
    maxWidth: '800px',
  },
  alignLeft: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px !important',
      maxWidth: '400px',
    },
  },
}));

const Terms = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.authentication.user);
  const [page, setPage] = useState(0);

  const form = useForm({
    resolver: yupResolver(consentSchema(user)),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const handleNext = async () => {
    const fieldNames = formSteps[page].fields
      .map((item) => item.name)
      .filter((i) => i);

    await form.trigger(fieldNames);
    if (Object.keys(form.formState.errors).length === 0) {
      if (page === 0) {
        setPage(1);
        return;
      }
      dispatch(patchUser({ tosEULAat: new Date() }))
        .unwrap()
        .then(() => navigate('/welcome'));
    }
  };

  const tosTitle = 'Image Upload Terms';

  const changeDate =
    'Well-Kept Beauty, Inc. Image Upload Terms – Version December 6, 2022';

  const preamble =
    'Well-Kept Beauty Inc.  d/b/a K’ept Health  (“Company”, “we”, ' +
    '“us”, or “our”) has created these Company Image Upload Terms (these “Upload Terms”).  ' +
    'The purpose of these Upload Terms is to inform you (“you”, “your”, or “yourself”) ' +
    'of the terms and conditions that govern your submission of images and image descriptions ' +
    'to K’ept Health at https://app.kept.health (the “Upload Site”) (collectively, ' +
    'the “Images”).  By submitting Images to K’ept Health at the Upload Site, ' +
    'you acknowledge that you understand, agree to, and accept, without limitation or ' +
    'qualification, all of the following terms and conditions of these Upload Terms ' +
    'and all of the terms and conditions of the K’ept Health Website Terms of Use ' +
    'found at www.kept.health (the “Website Terms of Use”) without modification:\n\n';

  const licenseGrant1 =
    '1. License Grant. You hereby grant K’ept Health a non-exclusive, ' +
    'transferable, sublicensable, royalty-free, perpetual, irrevocable, unrestricted, ' +
    'worldwide license to use, copy, download, print, display, transmit, watermark, ' +
    'extract, reproduce, repurpose, reutilize, modify, publish, market, sell, transfer, ' +
    'assign, loan, license, sublicense, and distribute the Images and anything included in ' +
    'them without attribution and without compensation of any kind for any purpose ' +
    'whatsoever, whether commercial or non-commercial [(including, without limitation, ' +
    'using the Images for patient care, education, and research, using the Images in ' +
    'lectures, reports, research papers, and scientific posters, publishing the ' +
    'Images in print and electronic media, posting the Images on websites, adding ' +
    'the Images to image libraries, centralized image repositories, and learning ' +
    'management systems, using the Images for performing vision science and machine ' +
    'learning and to train and test deep learning and artificial intelligence algorithms, ' +
    'and incorporating the Images into and otherwise using them in, for, or in connection ' +
    'with the development and commercialization of software and other products ' +
    'and services)].\n\n';

  const derivativeWorks2 =
    '2. Derivative Works and Products. Without limiting the ' +
    'generality of Section 1 above, K’ept Health may create, develop, and commercialize ' +
    '(a) derivative works based on the Images or any part thereof (“K’ept Health’s ' +
    'Derivative Works”) and (b) products and services that incorporate or otherwise ' +
    'use the Images or any part thereof in, for, or in connection with such products ' +
    'and services (“K’ept Health’s Products”) (K’ept Health’s Derivative Works ' +
    'and K’ept Health’s Products are collectively, “K’ept Health’s Derivative ' +
    'Works and Products”). K’ept Health will be the sole and exclusive owner of all ' +
    'right, title, and interest in and to K’ept Health’s Derivative Works and Products ' +
    '(including, without limitation, all associated patent, copyright, trademark, trade ' +
    'secret, moral, and other intellectual property rights related thereto).  To the ' +
    'extent that all right, title, and interest in and to any of K’ept Health’s ' +
    'Derivative Works and Products may not by operation of law fully vest in K’ept ' +
    'Health, you hereby irrevocably assign all your right, title, and interest therein ' +
    'to K’ept Health without further compensation.\n\n';

  const authority3 =
    '3. Authority to Submit the Images. You represent and warrant ' +
    'to K’ept Health that the Images you submit to K’ept Health are owned by you ' +
    'or your licensors free and clear of all liens, encumbrances, and restrictions ' +
    'of any kind or nature whatsoever and you have full power and authority, and all ' +
    'necessary rights, approvals, and consents to submit them to K’ept Health for use ' +
    'in accordance with these Upload Terms without violating or infringing on any ' +
    'rights of any person or entity (including, without limitation, any patent, ' +
    'copyright, trademark, trade secret, moral, or other intellectual property ' +
    'rights), privacy laws, laws governing the confidentiality of patient ' +
    'information and medical records such as HIPAA, or other applicable laws or ' +
    'regulations. You will, at your sole expense, indemnify, defend, and hold ' +
    'harmless K’ept Health and K’ept Health’s officers, directors, managers, ' +
    'members, shareholders, agents, representatives, successors, and assigns from ' +
    'and against any and all claims, suits, liens, actions, judgments, damages, ' +
    'losses, penalties, interest, professional fees (including, without limitation, ' +
    'reasonable attorneys’ fees and disbursements), costs, court costs, expenses, ' +
    'disbursements, and liabilities of any kind or nature whatsoever resulting from, ' +
    'arising out of, or related to any breach of or inaccurate, false, or fraudulent ' +
    'representation or warranty made by you in this Section 3.  K’ept Health reserves ' +
    ' the right, at K’ept Health’s own expense, to assume the exclusive defense and ' +
    'control of any matter otherwise subject to indemnification by you pursuant to ' +
    'this Section 3, in which event you will cooperate with K’ept Health in asserting ' +
    'any available defenses.\n\n';

  const acceptance4 =
    '4. Image Acceptance. K’ept Health, at its sole discretion, ' +
    'may accept or deny any Images (or any part thereof) you submit to K’ept Health ' +
    'for any reason or no reason whatsoever and you will not necessarily be notified ' +
    'if your submitted Images (or any part thereof) have been accepted or denied. ' +
    '[If any of your submitted Images (or any part thereof) are denied, they will be ' +
    'removed from K’ept Health’s system without notice.]  Without limiting the ' +
    'generality of the foregoing, the Images you submit should contain no personally ' +
    'identifiable information as K’ept Health may deny your submitted Images (or any ' +
    'part thereof) if they do or if K’ept Health cannot modify them sufficiently ' +
    'enough to eliminate any such personally identifiable information or reduce the ' +
    'likelihood that those individuals photographed in the submitted Images will be ' +
    'identifiable as determined by K’ept Health in its sole discretion.\n\n';

  const terms5 =
    '5. Website Terms of Use. Both these Upload Terms and the Website Terms ' +
    'of Use govern your submission of Images to K’ept Health at the Upload Site. ' +
    'These Upload Terms are in addition to and not in lieu of the Website Terms ' +
    'of Use and do not replace or limit them in any way.  If there is any conflict ' +
    'between these Upload Terms and the Website Terms of Use, these Upload Terms ' +
    'will control with respect to your submission of Images to K’ept Health at ' +
    'the Upload Site.';

  return (
    user && (
      <Box className={classes.mainBox}>
        {page === 0 && (
          <Box margin="auto">
            <Box sx={{ mb: 2 }}>
              <Typography variant="h1">{tosTitle}</Typography>
              <Typography variant="h5">{changeDate}</Typography>
            </Box>
            <Box
              height="370px"
              sx={{
                overflow: 'auto',
                border: '1px solid silver',
                borderRadius: '5px',
                padding: '10px 15px',
                textAlign: 'left',
              }}
            >
              <Typography>
                {preamble} <br />
                <br /> {licenseGrant1} <br />
                <br /> {derivativeWorks2} <br />
                <br /> {authority3}
                <br />
                <br /> {acceptance4} <br />
                <br /> {terms5}
              </Typography>
            </Box>
          </Box>
        )}
        <Form
          steps={formSteps}
          form={form}
          startPage={page}
          FormButtons={
            <Grid container justifyContent="space-between" margin="auto">
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <PrimaryButton
                  onClick={() => setPage(0)}
                  sx={{
                    backgroundColor: '#FFD734',
                    borderRadius: '0px',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  disabled={page === 0}
                >
                  Previous
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.gridItem}>
                <PrimaryButton
                  onClick={handleNext}
                  sx={{
                    backgroundColor: '#FFD734',
                    borderRadius: '0px',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {page === 0 ? 'Next' : 'Submit'}
                </PrimaryButton>
              </Grid>
            </Grid>
          }
        />
      </Box>
    )
  );
};

export default Terms;
