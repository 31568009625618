/* eslint-disable import/no-unresolved */
import { Box, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Zoom, Navigation, Pagination } from 'swiper';
import DeleteIcon from '@mui/icons-material/Delete';
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import FileIcon from '../../static/file-icon.png';

const ImageSlider = ({ images, showDelete, deleteImage }) => {
  const getSource = (file) => {
    if (file instanceof File) {
      if (
        ['jpeg', 'jpg', 'png'].includes(
          file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
        )
      ) {
        return URL.createObjectURL(file);
      }
      return FileIcon;
    }
    if (
      ['jpeg', 'jpg', 'png']
        .map((ext) => file.thumbnailFileUrl.includes(ext))
        .includes(true)
    ) {
      return file.thumbnailFileUrl;
    }
    return FileIcon;
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1, mt: 2 }}>
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        zoom
        navigation
        pagination={{
          clickable: true,
        }}
        modules={[Zoom, Navigation, Pagination]}
        className="mySwiper"
      >
        {images.map((item, index) => (
          <SwiperSlide key={item?.lastModified || item.thumbnailFileUrl}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: 50,
                bgcolor: '#FAFAFA',
              }}
            >
              <Typography variant="h5" sx={{ margin: 'auto' }}>
                {item.name}
              </Typography>
              {showDelete && (
                <DeleteIcon
                  sx={{ verticalAlign: 'middle', marginRight: '10px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    deleteImage(index);
                  }}
                />
              )}
              <a href={item.thumbnailFileUrl} target="_blank" rel="noreferrer">
                <OpenInNewIcon sx={{ color: 'black', paddingRight: '10px' }} />
              </a>
            </Box>
            <Tooltip
              title={
                ['jpeg', 'jpg', 'png'].includes(
                  item.name.slice(((item.name.lastIndexOf('.') - 1) >>> 0) + 2)
                ) && 'Double-click to zoom.'
              }
            >
              <div className="swiper-zoom-container">
                <Box
                  component="img"
                  sx={{
                    height: 255,
                    display: 'block',
                    maxWidth: 400,
                    overflow: 'hidden',
                    width: ['jpeg', 'jpg', 'png'].includes(
                      item.name.slice(
                        ((item.name.lastIndexOf('.') - 1) >>> 0) + 2
                      )
                    )
                      ? '100%'
                      : '50%',
                    objectFit: 'cover !important',
                  }}
                  src={getSource(item)}
                  alt={item.name}
                />
              </div>
            </Tooltip>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      thumbnailFileUrl: PropTypes.string,
      originalFileUrl: PropTypes.string,
    })
  ).isRequired,
  showDelete: PropTypes.bool.isRequired,
  deleteImage: PropTypes.func.isRequired,
};

export default ImageSlider;
