import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { getCases, showCaseForm } from '../../store/cases/actions';
import SecondaryButton from '../../components/ui/SecondaryButton';
import { capitalizeFirstLetter } from '../../utils/utils';
import Table from '../../components/ui/Table';
import CaseForm from './components/CaseForm';
import { getUser } from '../../store/authentication/actions';
import { Roles } from '../../utils/constants';

const ReviewButton = (row) => (
  // eslint-disable-next-line react/destructuring-assignment
  <Link to={`/cases/${row.id}`}>
    <SecondaryButton text="Review" disabled={false} />
  </Link>
);

const Cases = () => {
  const dispatch = useDispatch();
  const cases = useSelector((state) => state.cases.cases);
  const isLoading = useSelector((state) => state.cases.loading);
  const showForm = useSelector((state) => state.cases.showCaseForm);
  const user = useSelector((state) => state.authentication.user);
  const [headCells, setHeadCells] = useState(null);

  useEffect(() => {
    if (user !== null) {
      setHeadCells([
        {
          id: 'id',
          label: 'ID',
          props: {
            align: 'center',
          },
        },
        {
          id: 'caseBrief',
          label: 'caseBrief',
          value: (row) => row.caseBrief,
          props: {
            align: 'center',
          },
        },
        {
          id: 'createdAt',
          label: 'Submitted',
          value: (row) =>
            capitalizeFirstLetter(moment(row.createdAt).fromNow()),
          props: {
            align: 'center',
          },
        },
        {
          id: 'createdBy',
          label: 'Practitioner',
          value: (row) =>
            `${row.createdBy.firstName} ${row.createdBy.lastName}`,
          condition: user.role === Roles.ADMIN,
          props: {
            align: 'center',
          },
        },
        {
          id: 'review-button',
          // eslint-disable-next-line react/no-unstable-nested-components
          value: ReviewButton,
          props: {
            size: 'small',
            align: 'right',
            sx: {
              width: '10%',
            },
          },
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    if (cases === null) {
      dispatch(getCases());
    }
    if (user === null) {
      dispatch(getUser());
    }
  }, []);

  return (
    <Box padding="3% 2%">
      {showForm ? (
        <CaseForm />
      ) : (
        headCells && (
          <Table
            columns={headCells}
            data={cases || []}
            isLoading={isLoading}
            header={
              <Box
                padding="15px 10px"
                display="flex"
                justifyContent="space-between"
              >
                {user && (
                  <SecondaryButton
                    onClick={
                      user.role === Roles.ADMIN
                        ? () => dispatch(getCases())
                        : () => dispatch(showCaseForm(true))
                    }
                    text={
                      user.role === Roles.ADMIN ? 'Refresh' : 'Create a case'
                    }
                    disabled={user.role === Roles.ADMIN ? isLoading : false}
                    progressProps={{
                      sx: { color: 'black' },
                    }}
                    buttonProps={{
                      sx: {
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: '0',
                      },
                    }}
                  />
                )}
              </Box>
            }
          />
        )
      )}
    </Box>
  );
};

export default Cases;
