import { forwardRef, useEffect, useState } from 'react';

import { Box, Grid, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import FormFields from './FormFields';

const Form = forwardRef(
  (
    {
      onSubmit,
      steps,
      form: { register, control, formState, handleSubmit, getValues, setValue },
      startPage,
      isLoading,
      FormButtons,
      className,
      renderInputFields,
      formOptions,
    },
    ref
  ) => {
    const [step, setStep] = useState(null);

    useEffect(() => {
      if (steps) {
        setStep(steps[startPage]);
      }
    }, [steps, startPage]);

    return (
      <Paper
        sx={{
          background: 'white',
          textAlign: 'start',
          ...(!step?.mainIcon && { justifyContent: 'space-around' }),
          display: 'flex',
          ...formOptions,
        }}
        elevation={0}
      >
        {steps && (
          <Box
            className={className}
            sx={{
              ...(step?.fullWidth ? { width: '100%' } : { width: '80%' }),
            }}
          >
            <Grid container>
              {step?.title && (
                <Grid item xs={12} sx={{ pb: 4, textAlign: 'center' }}>
                  <Typography variant="h3">{step.title}</Typography>
                </Grid>
              )}
            </Grid>
            <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
              <Grid container>
                {step && (
                  <FormFields
                    register={register}
                    control={control}
                    errors={formState.errors}
                    page={step}
                    getValues={getValues}
                    setValue={setValue}
                    isLoading={isLoading}
                    renderInputFields={renderInputFields}
                  />
                )}
              </Grid>
              {FormButtons !== null && (
                <Box textAlign="center" margin="30px auto">
                  {FormButtons}
                </Box>
              )}
            </form>
          </Box>
        )}
      </Paper>
    );
  }
);

Form.propTypes = {
  onSubmit: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  startPage: PropTypes.number,
  isLoading: PropTypes.bool,
  FormButtons: PropTypes.element,
  renderInputFields: PropTypes.bool,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formOptions: PropTypes.object,
};

Form.defaultProps = {
  onSubmit: () => {},
  startPage: 0,
  isLoading: false,
  FormButtons: null,
  renderInputFields: true,
  className: '',
  formOptions: {},
};

export default Form;
