import * as yup from 'yup';
import { step1Schema } from '../../Cases/components/formDetails';

export const caseFileConditionSchema = yup.object().shape({
  condition: yup.string().required('The condition field is required.'),
});

export const formSteps = [
  {
    stepNo: 1,
    schema: step1Schema,
    fields: [
      {
        name: 'condition',
        fieldId: 'condition',
        label: 'Condition(s)',
        helpText:
          'To the best of your ability, please name the condition that is pictured.',
        type: 'input-text',
        isRequired: true,
        size: 12,
      },
    ],
  },
];
