import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Box, Grid, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';

const Banner = ({ field }) => {
  const { width } = useWindowDimensions();

  return (
    <Paper elevation={0}>
      <Grid
        space={0}
        padding={2}
        container
        sx={{
          border: 1,
        }}
      >
        <Grid
          item
          xs={width < 700 ? 12 : 2}
          md={width < 700 ? 12 : 1}
          align="center"
        >
          <InfoRoundedIcon sx={{ color: 'black' }} />
        </Grid>
        <Grid item xs={width < 700 ? 12 : 10} md={width < 700 ? 12 : 10}>
          <Box>
            {field?.contents?.map((item) => {
              if (item.type === 'p') {
                if (Array.isArray(item.content)) {
                  return item.content.map((t) => {
                    if (typeof t === 'object') {
                      return (
                        <Box key={item.id + t.value}>
                          <Typography variant="p" {...t?.props}>
                            {t.value}
                          </Typography>
                          <br />
                        </Box>
                      );
                    }
                    return (
                      <Box key={item.id + t}>
                        <Typography variant="p">{t}</Typography>
                        <br />
                      </Box>
                    );
                  });
                }
                return item.content.split('\n').map((t) => (
                  <Box key={item.id + t}>
                    <Typography variant="p">{t}</Typography>
                    <br />
                  </Box>
                ));
              }
              if (item.type === 'ul') {
                return (
                  <Box key={item.id + item.title}>
                    {item?.title && (
                      <Typography {...item?.props} variant="h4">
                        {item.title}
                      </Typography>
                    )}
                    <ul key={item.id}>
                      {item.content.map((li) => (
                        <li key={li}>{li}</li>
                      ))}
                    </ul>
                  </Box>
                );
              }
              // eslint-disable-next-line react/jsx-no-useless-fragment
              return <></>;
            })}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

Banner.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

export default Banner;
