import { useEffect, useState } from 'react';

import { useFieldArray } from 'react-hook-form';
import { Button, Grid, Typography, Box } from '@mui/material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import PropTypes from 'prop-types';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { Accordion, AccordionDetails, AccordionSummary } from './ui/Accordion';
import ErrorBox from './ui/ErrorBox';

const FormFieldsArray = ({
  constructField,
  fields,
  control,
  page,
  errors,
  renderInputFields,
}) => {
  const [expanded, setExpanded] = useState('panel1');
  const [panelCount, setPanelCount] = useState(1);

  const {
    fields: groupedFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: page.multiple.name,
  });

  useEffect(() => {
    setPanelCount(groupedFields.length);
  }, [groupedFields]);

  useEffect(() => {
    setExpanded(`panel${panelCount}`);
  }, [panelCount]);

  useEffect(() => {
    if (
      Array.isArray(errors[page.multiple.name]) &&
      errors[page.multiple.name].length > 0 &&
      errors[page.multiple.name].filter((item) => item !== undefined).length > 0
    ) {
      setExpanded(
        `panel${
          errors[page.multiple.name].findIndex((item) => item !== undefined) + 1
        }`
      );
    }
  }, [errors]);

  return (
    <Box width="100%" mb={3}>
      {errors[page.multiple.name]?.message && (
        <Box margin="auto" textAlign="center">
          <ErrorBox message={errors[page.multiple.name]?.message} />
        </Box>
      )}
      {groupedFields.map((item, index) => (
        <Grid item xs={12} md={12} key={item.id}>
          <Accordion
            expanded={expanded === `panel${index + 1}`}
            onChange={() => setExpanded(`panel${index + 1}`)}
          >
            <AccordionSummary>
              <Typography variant="h4">{`${page.multiple.placeholder} ${
                index + 1
              }`}</Typography>
              <Button
                sx={{ marginLeft: 'auto', marginRight: '0' }}
                onClick={() => {
                  remove(index);
                  setPanelCount((oldCount) => oldCount - 1);
                }}
              >
                <DeleteForeverRoundedIcon sx={{ color: 'black' }} />
              </Button>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowSpacing={4}>
                {fields?.map((field) => (
                  // eslint-disable-next-line no-nested-ternary
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={field.size}
                    lg={field.size}
                    xl={field.size}
                    key={`${item.id}${field.name}`}
                    sx={{
                      margin: `15px ${
                        field.size !== 12 && renderInputFields
                          ? '0 0 auto'
                          : '0'
                      }`,
                    }}
                  >
                    {constructField(
                      {
                        ...field,
                        name: `${page.name}.${index}.${field.name}`,
                      },
                      renderInputFields
                    )}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
      <Grid item xs={12} md={12} sm={12} lg={12}>
        <Button
          fullWidth
          size="large"
          onClick={() => {
            append(page.multiple.defaultValues);
            setPanelCount((oldCount) => oldCount + 1);
          }}
          startIcon={<AddBoxIcon sx={{ color: '#FFD734' }} />}
          sx={{
            background: 'white',
            color: 'black',
            textTransform: 'none',
            border: '1px solid silver',
            borderLeft: '4px solid #FFD734',
            align: 'left',
            justifyContent: 'flex-start',
            marginTop: '15px',
          }}
        >
          {`Add a ${page.multiple.placeholder}`}
        </Button>
      </Grid>
    </Box>
  );
};

FormFieldsArray.propTypes = {
  page: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  constructField: PropTypes.func.isRequired,
  control: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  fields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  renderInputFields: PropTypes.bool,
};

FormFieldsArray.defaultProps = {
  renderInputFields: true,
};

export default FormFieldsArray;
