import { createBrowserRouter, Navigate } from 'react-router-dom';

import { PrivateRoutes, PublicRoutes } from './Routes';
import DefaultLayout from '../components/layout/DefaultLayout';
import AuthenticatedLayout from '../components/layout/AuthenticatedLayout';
import Error from '../utils/Error';

const router = createBrowserRouter([
  {
    element: <DefaultLayout />,
    children: [
      ...PublicRoutes.map((route) => ({
        path: route.path,
        element: <route.view />,
      })),
      { path: '*', element: <Navigate to="/login" /> },
    ],
  },
  {
    element: <AuthenticatedLayout />,
    children: PrivateRoutes.map((route) => ({
      path: route.path,
      element: <route.view />,
      errorElement: <Error />,
    })),
  },
]);

export default router;
