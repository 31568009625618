import { Box, Typography, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';

import { useState } from 'react';
import Form from '../../components/Form';
import {
  resendVerificationCode,
  verifyAccount,
} from '../../store/authentication/actions';
import {
  codeFormSchema,
  codeFormSteps,
  emailFormSchema,
  emailFormSteps,
} from './components/formDetails';
import PrimaryButton from '../../components/ui/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    width: '100%',
    padding: '0 70px',
  },
  logInButton: {
    marginBottom: '30px',
    textTransform: 'uppercase',
  },
  gridContainer: {
    justifyContent: 'space-between',
    margin: 'auto',
  },
  alignLeft: {
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px !important',
    },
  },
}));

const VerificationCode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState('code');
  const isLoading = useSelector((state) => state.authentication.loading);
  const classes = useStyles();

  const codeForm = useForm({
    resolver: yupResolver(codeFormSchema),
    mode: 'onChange',
  });

  const emailForm = useForm({
    resolver: yupResolver(emailFormSchema),
    mode: 'onChange',
  });

  const verifyCode = (values) => {
    dispatch(verifyAccount(values))
      .unwrap()
      .then((data) => {
        toast.success(data?.message);
        navigate('/login');
      });
  };

  const sendVerificationCode = (values) => {
    dispatch(resendVerificationCode(values));
    setShowForm('code');
  };

  return (
    <Box width="100%" padding="0 70px">
      <Box sx={{ marginBottom: '30px' }}>
        <Typography variant="h3" textTransform="uppercase">
          Email Verification
        </Typography>
      </Box>
      <Form
        steps={showForm === 'code' ? codeFormSteps : emailFormSteps}
        form={showForm === 'code' ? codeForm : emailForm}
        onSubmit={showForm === 'code' ? verifyCode : sendVerificationCode}
        FormButtons={
          <Grid container justifyContent="space-between" margin="auto">
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className={classes.gridItem}
            >
              {showForm === 'code' && (
                <PrimaryButton
                  onClick={() => {
                    if (showForm === 'code') {
                      setShowForm('email');
                    } else {
                      setShowForm('code');
                    }
                  }}
                  sx={{
                    backgroundColor: '#FFD734',
                    borderRadius: '0px',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                  type="submit"
                >
                  Resend code
                </PrimaryButton>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              className={classes.gridItem}
            >
              <PrimaryButton
                type="submit"
                sx={{
                  backgroundColor: '#FFD734',
                  borderRadius: '0px',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                disabled={isLoading}
              >
                {showForm === 'code' ? 'Verify' : 'Send code'}
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
    </Box>
  );
};

export default VerificationCode;
