import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Box,
  Stack,
  Pagination,
  PaginationItem,
  Dialog,
  CircularProgress,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import _ from 'lodash';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { getUser } from '../../store/authentication/actions';
import { getPaginatedFiles } from '../../store/files/actions';
import FileIcon from '../../static/file-icon.png';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import ImageDetailsDialog from './components/ImageDetailsDialog';

const Gallery = () => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.files.data.files);
  const filesCount = useSelector((state) => state.files.data.totalCount);
  const [images, setImages] = useState([]);
  const user = useSelector((state) => state.authentication.user);
  const [prevPage, setPrevPage] = useState(0);
  const limit = 12;
  const { width } = useWindowDimensions();
  const [cursor, setCursor] = useState(null);
  const [imageModalData, setImageModalData] = useState({
    open: false,
    caseFileId: null,
    selectedFile: null,
  });
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [user]);

  const isImage = (item) =>
    ['jpeg', 'jpg', 'png']
      .map((ext) => item.thumbnailFileUrl.includes(ext))
      .includes(true);

  const handlePagination = (pageNumber) => {
    if (
      prevPage < pageNumber &&
      prevPage + 1 !== pageNumber &&
      files.length < (pageNumber - 1) * limit
    ) {
      return;
    }

    if (files && files.length > (pageNumber - 1) * limit) {
      setLoadedImages(
        files
          .slice((pageNumber - 1) * limit, pageNumber * limit)
          .map((i) => i.id)
      );
      setImages(files.slice((pageNumber - 1) * limit, pageNumber * limit));
    } else {
      dispatch(getPaginatedFiles({ cursor, limit }))
        .unwrap()
        .then((data) => {
          setImages(data.files);
          if (data.files.length > 0) {
            setCursor(data.files[data.files.length - 1].id);
          }
        });
      setLoadedImages([]);
    }
    setPrevPage(pageNumber);
  };

  useEffect(() => {
    handlePagination(1);
  }, []);

  return (
    <Box padding="2% 4%">
      {images.length !== 0 ? (
        <>
          <ImageList
            sx={{
              width: '100%',
              height: '80vh',
              display:
                _.xor(
                  loadedImages,
                  images.map((i) => i.id)
                ).length !== 0 && 'none',
            }}
            gap={10}
            cols={width < 650 ? 1 : 4}
          >
            {images.map((item) => (
              <ImageListItem
                key={item.thumbnailFileUrl}
                sx={{ height: images.length > 4 ? 'auto' : '50% !important' }}
              >
                <img
                  src={isImage(item) ? item.thumbnailFileUrl : FileIcon}
                  alt={item.name}
                  style={!isImage(item) ? { width: '70%', margin: 'auto' } : {}}
                  onLoad={() =>
                    setLoadedImages((prevLoadedImages) => {
                      if (!prevLoadedImages.includes(item.id)) {
                        return [...prevLoadedImages, item.id];
                      }
                      return prevLoadedImages;
                    })
                  }
                />
                <ImageListItemBar
                  sx={{
                    fontFamily: 'Avenir',
                    background: 'rgba(0, 0, 0, 0.7)',
                  }}
                  title={item.name}
                  actionIcon={
                    <Box>
                      <a
                        style={{
                          color: 'rgba(255, 255, 255, 0.54)',
                          verticalAlign: 'middle',
                        }}
                        href={item.originalFileUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <OpenInNewIcon sx={{ paddingTop: '3px' }} />
                      </a>
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`change condition for image ${item.name}`}
                        onClick={() =>
                          setImageModalData({
                            open: true,
                            caseFileId: item.caseFileId,
                            selectedFile: item,
                          })
                        }
                      >
                        <InfoIcon />
                      </IconButton>
                    </Box>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          {_.xor(
            loadedImages,
            images.map((i) => i.id)
          ).length !== 0 && (
            <Box height="80vh" display="flex" margin="auto">
              <CircularProgress
                sx={{ margin: 'auto', color: 'rgb(186, 150, 15)' }}
              />
            </Box>
          )}
          <Stack spacing={2}>
            <Pagination
              sx={{ margin: 'auto' }}
              count={filesCount ? Math.ceil(filesCount / limit) : 0}
              onChange={(e, pageNumber) => handlePagination(pageNumber)}
              renderItem={(item) => (
                <PaginationItem
                  sx={{ fontSize: '18px' }}
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                  disabled={
                    Math.ceil(filesCount / limit) === 1 ||
                    (prevPage + 1 >= item.page &&
                      files &&
                      files.length < (item.page - 1) * limit)
                  }
                />
              )}
            />
          </Stack>
        </>
      ) : (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            width: '80%',
            margin: 'auto',
            transform: 'translateY(-50%)',
          }}
        >
          <Typography variant="h2">There are no case files to show.</Typography>
        </Box>
      )}
      <Dialog
        open={imageModalData.open}
        onClose={(val) =>
          setImageModalData((oldImageModalData) => ({
            ...oldImageModalData,
            open: val,
          }))
        }
        maxWidth="md"
        keepMounted={false}
      >
        <ImageDetailsDialog
          open={imageModalData.open}
          setOpen={(val) => {
            setImageModalData({
              open: val,
              caseFileId: null,
              selectedFile: null,
            });
          }}
          caseFileId={imageModalData.caseFileId}
          selectedFile={imageModalData.selectedFile}
        />
      </Dialog>
    </Box>
  );
};

export default Gallery;
