import { useEffect, useState } from 'react';

import {
  FormControl,
  FormLabel,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorBox from './ErrorBox';

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'error',
})(({ error }) => ({
  backgroundColor: '#FAFAFA',
  borderRadius: '6px',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: error ? '1px solid rgba(222, 136, 133, 1)' : '1px solid #D4D4D4',
    },
    '&:hover fieldset': {
      border: error ? '1px solid rgba(222, 136, 133, 1)' : '1px solid black',
    },
    '&.Mui-focused fieldset': {
      border: error ? '1px solid rgba(222, 136, 133, 1)' : '1px solid black',
    },
  },
  '& input': {
    color: error ? 'rgba(171, 63, 58, 1)' : 'rgba(66, 66, 66, 1)',
    fontFamily: 'Avenir',
    fontWeight: 'bold',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
}));

const TextFieldLocal = ({ field, register, errors, isLoading }) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setShowPassword(field.type !== 'input-password');
  }, [field]);

  const getType = () => {
    switch (field.type) {
      case 'input-email':
        return 'email';
      case 'input-url':
        return 'url';
      case 'input-number':
        return 'number';
      case 'input-tel':
        return 'tel';
      case 'input-password':
        return showPassword ? 'text' : 'password';
      default:
        return 'text';
    }
  };

  return (
    <FormControl component="fieldset" sx={{ minWidth: '100%' }}>
      {isLoading ? (
        <Skeleton animation="wave" />
      ) : (
        <>
          <FormLabel
            component="legend"
            sx={{ mb: 2, ...(field.label === '' && { minHeight: '20px' }) }}
          >
            <Typography
              variant="h5"
              sx={{
                color: '#525252',
              }}
              fontWeight="bold"
              fontSize="16px"
            >
              {field?.label}
              &nbsp;
              {field?.isRequired && <span>*</span>}
            </Typography>
            <Typography variant="body1" component="span">
              {field?.helpText}
            </Typography>
          </FormLabel>
          <StyledTextField
            id={field?.name}
            className={field?.fieldClass}
            name={field?.name}
            size="medium"
            placeholder={field?.placeholder}
            onKeyDown={(e) => {
              if (field.type === 'input-tel') {
                if (
                  (e.keyCode >= 65 && e.keyCode <= 90) ||
                  (e.keyCode >= 144 && e.keyCode < 222 && e.key !== '-')
                ) {
                  e.preventDefault();
                }
              }
              if (field.type === 'input-number') {
                if (e.key === 'e') {
                  // eslint-disable-next-line no-param-reassign
                  e.preventDefault();
                }
                if (e.key === '-') {
                  // eslint-disable-next-line no-param-reassign
                  e.preventDefault();
                }
                if (e.key === '.') {
                  // eslint-disable-next-line no-param-reassign
                  e.preventDefault();
                }
              }
            }}
            onKeyUp={(e) => {
              if (
                Object.prototype.hasOwnProperty.call(field, 'min') &&
                !Number.isNaN(parseInt(e.target.value, 10))
              ) {
                // eslint-disable-next-line no-param-reassign
                if (e.target.value < field?.min) e.target.value = '';
              }
            }}
            InputLabelProps={{
              min: 0,
              shrink: true,
            }}
            minRows={field.type === 'input-text-area' ? 5 : 1}
            multiline={field.type === 'input-text-area'}
            disabled={field?.disabled}
            type={getType()}
            fullWidth
            {...register(field.name)}
            error={!!errors[field.fieldId]}
            InputProps={{
              startAdornment: _.get(errors, field.name)?.message && (
                <InputAdornment position="end" sx={{ paddingRight: '7px' }}>
                  <WarningIcon sx={{ color: '#C75C57' }} />
                </InputAdornment>
              ),
              endAdornment: field.type === 'input-password' && (
                <InputAdornment position="end">
                  <Typography
                    sx={{
                      fontFamily: 'Avenir',
                      fontSize: '15px',
                      cursor: 'pointer',
                      textTransform: 'uppercase',
                      fontWeight: 'bold',
                    }}
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword((oldPassword) => !oldPassword)
                    }
                  >
                    {showPassword ? 'HIDE' : 'SHOW'}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <ErrorBox
            message={_.get(errors, field.name)?.message}
            showIcon={false}
          />
        </>
      )}
    </FormControl>
  );
};

TextFieldLocal.propTypes = {
  field: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TextFieldLocal;
