import { createSlice } from '@reduxjs/toolkit';

import {
  isFulfilledAction,
  isPendingAction,
  isRejectedAction,
} from '../../utils/utils';
import { getUsers } from './actions';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const practitionersSlice = createSlice({
  name: 'practitioners',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.data = payload;
      })

      .addMatcher(isPendingAction('practitioners'), (state) => {
        state.loading = true;
      })
      .addMatcher(isRejectedAction('practitioners'), (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addMatcher(isFulfilledAction('practitioners'), (state) => {
        state.loading = false;
      });
  },
});

export default practitionersSlice.reducer;
