import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequest } from '../../utils/api';

export const getPaginatedFiles = createAsyncThunk(
  'caseFiles/files/get',
  async (values, { rejectWithValue }) => {
    const queryParams = {};
    if (values?.cursor) {
      queryParams.cursor = values.cursor;
    }
    if (values?.limit) {
      queryParams.limit = values.limit;
    }
    const query = Object.keys(queryParams).map(
      (key) => `${key}=${queryParams[key]}&`
    );
    return makeRequest(
      `/case-files/files?${query.join('')}`,
      'GET',
      values,
      rejectWithValue
    );
  }
);

export const getCaseFileById = createAsyncThunk(
  'getCaseFileById/get',
  async (values, { rejectWithValue }) =>
    makeRequest(
      `/case-files/${values.caseFileId}`,
      'GET',
      values,
      rejectWithValue
    )
);

export const updateCaseFile = createAsyncThunk(
  'getCaseFileById/patch',
  async (values, { rejectWithValue }) =>
    makeRequest(
      `/case-files/${values.caseFileId}`,
      'PATCH',
      values,
      rejectWithValue
    )
);
